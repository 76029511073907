import React from 'react';
import { Link } from 'react-router-dom';


import { ContentFooter, TitulosFooter, TextoFooter, ContentLink } from "./Estilos";

function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
}


const Footer = () => {
    const dataFrame = '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.6685459101845!2d-58.46885948519582!3d-34.587252464133165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb609773509e3%3A0x30b216e13ea7f81e!2sAtanasio%20Girardot%201449%2C%20C1427%20AKE%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1631038980476!5m2!1ses-419!2sar"></iframe>'

    return (
        <ContentFooter>
            <div className='row mt-4 mb-4'>
                <div className="col-md-10 mx-auto">
                    <div className="row">
                        <div className="col-md-4 p-4">
                            <TitulosFooter>
                                ¿Donde estamos?
                            </TitulosFooter>
                            <Iframe iframe={dataFrame} />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3 p-4">
                            <TitulosFooter>
                                Rueda Digital
                            </TitulosFooter>

                            <ContentLink>
                                <Link to='/Productos'>
                                    Productos
                                </Link>
                            </ContentLink>
                            <ContentLink>
                                <Link to='/Contacto'>
                                    Contacto
                                </Link>
                            </ContentLink>
                            <ContentLink>
                                <Link to='/Ayuda'>
                                    Ayuda
                                </Link>
                            </ContentLink>

                        </div>
                        <div className="col-md-4 p-4">
                            <TitulosFooter>
                                Atención al cliente
                            </TitulosFooter>
                            <TextoFooter>
                                Lunes a Viernes <br /> De 08:00 a 18:00
                            </TextoFooter>
                            <TextoFooter>
                                info@ruedadigital.com.ar
                            </TextoFooter>
                        </div>
                    </div>
                </div>
            </div>

        </ContentFooter>

    );
}



export default Footer;