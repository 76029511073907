import React, { useState } from 'react'
import { useEffect } from 'react'

import { get_Token } from "../helpers/getToken";

import { getAll_Categorias } from "../services/Categorias";
import { get_Carrito } from "../services/Users";
import { get_DataInicio } from '../services/Inicio'


const Context = React.createContext({})

export function UserContextProvider({ children }) {

    const [jwt, setJWT] = useState(() => get_Token())
    const [categorias, setCategorias] = useState([])
    const [carrito, setCarrito] = useState()
    const [items, setItems] = useState()
    const [inicio, setDataInicio] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const get_Data = async () => {
            const categorias = await getAll_Categorias()
            if (categorias) {
                const result = categorias.data.filter(unaCategoria => unaCategoria.Activo === true)
                setCategorias(result)
            } else {
                setCategorias([])
            }

            const dataInicio = await get_DataInicio()
            if(dataInicio){
                setDataInicio(dataInicio) 
            } else {
                setDataInicio([])
            }

            if(jwt){
                const carrito = await get_Carrito(jwt.token)
                if(carrito){
                    setCarrito(carrito.carrito)
                    setItems(carrito.items)
                }
            }

            setLoading(false)

        }

        get_Data()


    }, [jwt, items])

    return <Context.Provider value={{
        jwt,
        setJWT,
        carrito,
        setCarrito,
        items,
        setItems,
        categorias,
        inicio,
        setDataInicio,
        loading
    }}>
        {children}
    </Context.Provider>
}

export default Context