import React, {useState, useEffect} from 'react';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { user_ValidMail } from "../services/Users";

import Loading from "../components/Loading/Loading";

import { IsInValidUser, IsValidUser } from "../components/LoginForm/ValidUsers";

const ValidMail = () => {

    const {mail, userHash} = useParams()
    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState()

    useEffect(()=>{
        const validMail = async(mail, hash) => {
            setLoading(true)
            const result = await user_ValidMail(mail, hash)
            console.log(result)
            if(result){
                setIsValid(result)
            }
            setLoading(false)
        }
        validMail(mail, userHash)
    },[])

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <Helmet>
                <title>Rueda Digital - Validación de Usuario</title>
            </Helmet>
            
            <div>
                {
                    !isValid ?
                    <>
                        <IsInValidUser />
                    </>
                    :
                    <>
                        <IsValidUser />
                    </>
                }
            
            </div>
        </>

    );
}



export default ValidMail;