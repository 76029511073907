import styled from 'styled-components';

export const ContentMsgError = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
`

export const CardMensaje = styled.div`
    background: var(--Color-Blanco);
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
    border-radius: 24px;
    padding: 50px;

    & > img {
        width: 50%;
    }

    & > h2 {
        font-family: var(--Font-DMSans-Bold);
        font-size: 30px;
        margin: 0.5rem 0;
        color: var(--Color-Red-Primary);
        padding: 20px 40px 20px 0px;
    }

    & > p {
        font-family: var(--Font-DMSans-Regular);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
    }
`

export const MensajeErrorNotFound = styled.div`
    width: 50%;
    margin-top: 5%;
    margin-left: 25%;
    background: var(--Color-Blanco);
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
    border-radius: 24px;
    padding: 75px;

    &>h3{
        font-family: var(--Font-DMSans-Bold);
        font-size: 20px;
        color: var(--Color-Red-Primary);
        margin-bottom: 5%;
    }

    &>p{
        font-family: var(--Font-Roboto-Regular);
        color: var(--Color-Grey-Dark);
        font-size: 16px;
    }

`

export const ContentMensajeEnMantenimiento = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    &>div{
        width: 50%;
        background: var(--Color-Blanco);
        box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
        border-radius: 24px;
        padding: 75px;
    }

    & >div > img {
        width: 80%;
        margin-left: 10%;
        border-radius: 20px;
    }

    & >div > h3 {
        font-family: var(--Font-DMSans-Bold);
        font-size: 20px;
        color: var(--Color-Red-Primary);
        margin-bottom: 5%;
        text-align: center;
    }

    & >div > p{
        font-family: var(--Font-Roboto-Regular);
        color: var(--Color-Grey-Dark);
        font-size: 16px;
        text-align: center;
    }
`