import React from 'react';

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import { ContentCardConfirmPago, ImgLogoRueda, CapsulaEcommerce, TituloConfirm, MensajeConfirm, ContentCardErrorPago, TituloError, ContentCardPendingPago } from "./Estilos";
import { BotonPrimarioFondoBlanco, BotonPrimarioFondoRojo } from "../Botones/Botones";

export const CardConfirmPagos = () => {


    return (
        <div className="row mt-4">
            <div className="col-md-6 mx-auto">
                <ContentCardConfirmPago>
                    <div className="row mt-4 mb-2">
                        <div className="col-md-5">
                            <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                            <CapsulaEcommerce>
                                <p>E-Commerce</p>
                            </CapsulaEcommerce>
                        </div>
                    </div>
                    <TituloConfirm>
                        Hemos recibido tu pago
                    </TituloConfirm>
                    <MensajeConfirm>
                        Hemos recibido tu pago de forma correcta, el mismo lo podras retirar en Atanasio Girardot 1449, CABA de Lunes a Viernes entre las 08:00 y las 18:00.
                    </MensajeConfirm>

                </ContentCardConfirmPago>

            </div>
        </div>
    );
}


export const CardErrorPago = () => {

    return (
        <div className="row mt-4">
            <div className="col-md-6 mx-auto">
                <ContentCardErrorPago>
                    <div className="row mt-4 mb-2">
                        <div className="col-md-5">
                            <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                            <CapsulaEcommerce>
                                <p>E-Commerce</p>
                            </CapsulaEcommerce>
                        </div>
                    </div>
                    <hr />
                    <TituloError>
                        No pudimos procesar tu pago
                    </TituloError>
                        <MensajeConfirm>
                        No hemos podido procesar tu pago de forma correcta, si lo deseas puedes abonarlo en nuestras oficinas de Atanasio Girardot 1449, CABA de Lunes a Viernes entre las 08:00 y las 18:00.
                    </MensajeConfirm>
                    <hr />
                    <div className="row mt-4">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoBlanco 
                                link='/UserPanel'
                                texto='Ir a mi panel'
                            />

                        </div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoRojo 
                                link='/'
                                texto='Ir al inicio'
                            />
                        </div>
                    </div>
                </ContentCardErrorPago>

            </div>
        </div>
    )
}


export const CardPendingPago = () => {

    return (
        <div className="row mt-4">
            <div className="col-md-6 mx-auto">
                <ContentCardPendingPago>
                    <div className="row mt-4 mb-2">
                        <div className="col-md-5">
                            <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                            <CapsulaEcommerce>
                                <p>E-Commerce</p>
                            </CapsulaEcommerce>
                        </div>
                    </div>
                    <hr />
                    <TituloError>
                        Tu pago esta pendiente
                    </TituloError>
                        <MensajeConfirm>
                        Tu pago esta pendiente, si lo deseas puedes abonarlo en nuestras oficinas de Atanasio Girardot 1449, CABA de Lunes a Viernes entre las 08:00 y las 18:00.
                    </MensajeConfirm>
                    <hr />
                    <div className="row mt-4">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoBlanco 
                                link='/UserPanel'
                                texto='Ir a mi panel'
                            />

                        </div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoRojo 
                                link='/'
                                texto='Ir al inicio'
                            />
                        </div>
                    </div>
                </ContentCardPendingPago>

            </div>
        </div>
    )
}
