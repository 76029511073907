import styled, { keyframes } from 'styled-components';

export const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`

export const MessageError = styled.div`
    text-align: center;
    font-family: var(--Font-Roboto-Regular);
    font-size: 85%;
    margin-top: -1%;
    color: var(--Color-Red-Primary);
    animation: ${opacity} 1s ease;
`

export const TituloResultado = styled.div`
    text-align: center;
    font-family: var(--Font-Roboto-Regular);
    font-size: 100%;

    &>p{
        &>span{
            font-weight: bold;
            color: var(--Color-Red-Primary);
        }
    }

`