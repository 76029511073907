import React from 'react';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from "@react-pdf/renderer";

import { generaUnPrecioBase } from "../../helpers/generaPrecios";

import LogoRuedaImg from "../../assets/Logos/logo.svg";
import SinFoto from "../../assets/Images/sin_foto.png";

import { ContentItemOrden, IdOrden, TotalOrden, BtnVerOrdenes, ImgRueda, ImagenPrincipalProducto, TituloProducto, ButonGenerarOrden, ButonPagarConMercadoPago } from "./Estilos";
import DocuPdf from "./Docupdf";
import SinDatos from "../Errors/SinDatos";

const Botonera = () => {
    return (
        <div className="row">
            <div className="col-md-6 col-10 mx-auto">
                <hr />
                <div className="row">
                    <div className="col-md-6 col-6">
                        <BtnVerOrdenes>
                            <Link to='/UserPanel'>
                                Ir a mi panel
                            </Link>
                        </BtnVerOrdenes>
                    </div>
                    <div className="col-md-6 col-6">
                        <BtnVerOrdenes>
                            <Link to='/UserOrdenes'>
                                Ir a mis ordenes
                            </Link>
                        </BtnVerOrdenes>
                    </div>

                </div>
            </div>
        </div>
    )
}

const FormatTitulo = ({ titulo, cantidad }) => {
    return (
        <TituloProducto>
            {titulo}
            <span>{cantidad}</span>
            <small>Rollo/s</small>

        </TituloProducto>
    )
}


const FormatNumero = ({ numero, tipo }) => {
    const splitNumero = numero.split('.')
    return (
        <>
            <TotalOrden>
                {tipo}: <small>ARS $</small><span>{splitNumero[0]}</span>,<sup>{splitNumero[1]}</sup>
            </TotalOrden>
        </>
    )
}

const FormatFecha = ({ fecha }) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <>
            <TotalOrden>
                Fecha: <span>{new Date(fecha).toLocaleDateString("es-ES", options)}</span>
            </TotalOrden>
        </>
    )
}

const EstadoOrden = ({ estado }) => {


    return (
        <>
            <TotalOrden>
                Estado: <span>{estado}</span>
            </TotalOrden>
        </>
    )
}



const CardVerUnaOrden = ({ orden }) => {

    const pagarOrden = () => {
        const confirm = window.confirm('Esta a punto de ser redireccionado para que puedas realizar tu pago, al finalizar el mismo volveras a nuestro sitio.')

        if(confirm){
            window.location.href=orden.LinksPago.Link
        }
    }


    return (
        <>
            {
                orden ?
                    <>
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                <ContentItemOrden>
                                    <ImgRueda src={LogoRuedaImg} />
                                    <hr />
                                    <IdOrden className='text-center'>Orden Id: <span>{orden.Orden.Id.toString().padStart(4, "0000")}</span> </IdOrden>
                                    <br />
                                    <FormatNumero numero={orden.Orden.TotalOrden} tipo='Total' />
                                    <FormatFecha fecha={orden.Orden.CreatedAt} />
                                    <EstadoOrden estado={orden.Orden.EstadoPago} />
                                    <hr />
                                    <IdOrden>Orden detalle</IdOrden>
                                    {
                                        orden.DetallesOrden.map(unaOrden => (

                                            <div className="row mt-3 mb-3 p-2" key={unaOrden.Id}>
                                                <div className="col-md-3 col-10 mx-auto">
                                                    {
                                                        unaOrden.Producto.Imagenes.length === 0 ?
                                                            <>
                                                                <ImagenPrincipalProducto src={SinFoto} alt='Producto sin imagen' />
                                                            </>
                                                            :
                                                            <>
                                                                <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${unaOrden.Producto.Imagenes[0].URLImagen}`} alt={`Rueda Digital Producto ${unaOrden.Producto.Nombre}`} />
                                                            </>
                                                    }
                                                </div>
                                                <div className="col-md-9 col-10 mx-auto align-self-center">
                                                    <FormatTitulo titulo={unaOrden.Producto.Nombre} cantidad={unaOrden.Cantidad} />
                                                    <FormatNumero numero={generaUnPrecioBase(0, unaOrden.Producto.Precio, orden.Orden.TipoCambio)} tipo='Costo Item:' />

                                                </div>
                                            </div>



                                        ))
                                    }
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6 col-8 mb-2 mx-auto">
                                            <PDFDownloadLink
                                                document={<DocuPdf orden={orden} />}
                                                fileName={`Orden_RuedaDeigital_${orden.Orden.Id}`}
                                            >
                                                <ButonGenerarOrden>

                                                    <i className="bi bi-file-earmark-pdf"></i>
                                                    Generar PDF

                                                </ButonGenerarOrden>
                                            </PDFDownloadLink>




                                        </div>
                                        {
                                            orden.Orden.EstadoPago === 'Pendiente de Pago' ?
                                                <div className="col-md-6 col-8 mb-2 mx-auto">
                                                    <ButonPagarConMercadoPago onClick={()=>pagarOrden()}>
                                                        Pagar
                                                        <i className="bi bi-credit-card"></i>
                                                    </ButonPagarConMercadoPago>

                                                </div>
                                                :
                                                null

                                        }

                                    </div>


                                </ContentItemOrden>

                            </div>
                        </div>
                        <Botonera />
                    </>
                    :
                    <SinDatos
                        title='Ups ...'
                        message='No hemos podido recuperar tus datos. Por favor vuelvelo a intentar en unos minutos.'
                    />
            }

        </>
    );
}


export default CardVerUnaOrden;