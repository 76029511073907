import React, { useContext} from 'react';
import UserContext from "../context/userContext";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import Loading from "../components/Loading/Loading";


import CardsCategorias from "../components/CardsCategorias/CardsCategorias";

const Productos = () => {    
    const context = useContext(UserContext)

    return (
        <>
            <TituloPrincipal texto='Explora nuestros productos por categoria' />

            <div className="mt-4 mb-4">
                {
                    context.categorias ? 
                    <div className="row">
                        <div className="col-md-8 col-11 mx-auto">
                            <div className="row">
                                {
                                    context.categorias.map(unaCategoria => (
                                        <CardsCategorias categoria={unaCategoria} key={unaCategoria.Id} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Loading />
                }

            </div>
        </>
    ) 
}



export default Productos;