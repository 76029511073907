import styled from 'styled-components';

export const ContentModal = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, .5) cover;
   width: 100%;

   @media (max-width: 480px) {
        width: 100vww;
    }

   
`
export const ContentMensajeExito = styled.div`
    background: var(--Color-Blanco);
    padding: 50px;
    border-radius: 14px;
    width: 100%;
    border: 1px solid var(--Color-Green-Aproved);
    text-align: center;


    & > div > i {
        font-size: 100px;
        color: var(--Color-Green-Aproved)
    }

    & > div > h2 {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Green-Aproved)
    }

    
    & > div > p {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Negro);
        width: 80%;
        margin-left: 10%;
    }

    @media (max-width: 480px) {
        padding: 20px;
        & > div > i{
            font-size: 50px;
            margin-bottom: 5%;
        }

        & > div > h2{
            font-size: 18px;
        }

        & > div > p{
            font-size: 16px;
        }
    }

`

export const ContentMensajeError = styled.div`
    background: var(--Color-Blanco);
    padding: 50px;
    border-radius: 14px;
    width: 100%;
    border: 1px solid var(--Color-Red-Primary);
    text-align: center;

    & > div > i {
        font-size: 100px;
        color: var(--Color-Red-Primary);
        margin-bottom: 0;
    }

    & > div > h2 {
        font-size: 20px;
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Red-Primary);
        margin-bottom: 0;
    }

    
    & > div > p {
        font-size: 20px;
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Negro);
        width: 80%;
        margin-left: 10%;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        padding: 20px;
        & > div > i{
            font-size: 50px;
            margin-bottom: 5%;
        }

        & > div > h2{
            font-size: 18px;
        }

        & > div > p{
            font-size: 16px;
        }
    }

`

export const ContentMensajeUserRegister = styled.div`
    background: var(--Color-Blanco);
    padding: 50px;
    border-radius: 14px;
    width: 100%;
    border: 1px solid var(--Color-Green-Aproved);
`

export const ImgLogoRueda = styled.img`
    width: 100%;

    @media (max-width: 480px) {
        width: 40%;
    }
`

export const CapsulaEcommerce = styled.div`
    background-color: var(--Color-Red-Primary);
    border-radius: 44px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;

    &>p{
        font-family: var(--Font-DMSans-Regular);
        font-style: normal;
        font-size: 16px;
        color: var(--Color-Blanco);
        text-align: center;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        width: 40%;

        &>p{
            font-size: 12px;
        }
    }

`

export const BienvenidoText = styled.p`
    font-family: var(--Font-Roboto-Regular);
    color: var(--Color-Red-Primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.2px;
    margin-bottom: 5px;
    text-align: left;

    @media (max-width: 480px) {
        font-size: 16px;

    }
`

export const SubTituloBienvenido = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    text-align: left;

    @media (max-width: 480px) {
        font-size: 12px;

    }

`