import React, {useState, useEffect} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { add_Payment } from "../services/Users";

import Loading from "../components/Loading/Loading";
import {TituloPrincipal  } from "../components/Titulos/Titulos";
import { CardConfirmPagos, CardErrorPago, CardPendingPago  } from "../components/Pagos/CardsPagos";
import SinDatos from "../components/Errors/SinDatos";

export const ConfirmPagos = () => {
    const {hash} = useParams()
    const [searchParams] = useSearchParams();
    const idPay = searchParams.get('collection_id')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(()=> {
        const addData = async () => {
            const data = {
                Payment: idPay
            }

            const result = await add_Payment(hash, data)
            if(result){
                setLoading(false)
            }else{
                setError(true)
                setLoading(false)
            }
        }
        addData()
    },[])




    return (
        <>
            { loading ?
                    <Loading />
                :
                <>
                    { error ?
                        <>
                            <SinDatos 
                                title='Ups ...'
                                message='Lo sentimos, algo a salido mal. Por favor comunicate con nosotros'
                            />
                        </>
                        :
                        <>
                            <TituloPrincipal texto='Pago confirmado' />
                            <CardConfirmPagos />
                        </>
                    }

                </>

            }

        </>
    );
}



export const ErrorPago = () => {
    return (
        <>
            <TituloPrincipal texto='Pago no procesado' />
            <CardErrorPago />
        </>
    )
}


export const PendingPago = () => {
    return (
        <>
            <TituloPrincipal texto='Tu pago todavia esta pendiente' />
            <CardPendingPago />
        </>
    )
}