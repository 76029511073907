import React from 'react';
import { Helmet } from "react-helmet";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import ContactForm from "../components/ContactForm/ContactForm";

const Contacto = () => {
    return (
        <>
            <Helmet>
                <title>Rueda Digital - Contactanos</title>
            </Helmet>

            <TituloPrincipal texto='Contactanos' />

            <ContactForm />
        </>
    ) 
}



export default Contacto;