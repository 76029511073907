import { getAll_BannersImagen, getAll_BannersTexto } from "./Banners";
import { getAll_Categorias } from "./Categorias";
import { get_ProductosByCategoria_PerPage } from "./Productos";
import { get_ValorDolar } from "./CotizacionDolar";


const searchProductosCategorias = async (categoria) => {
    let index = 0
    let nameCategoriaUno 
    let productosCategoriaUno
    let nameCategoriaDos
    let productosCategoriaDos 

    while (index < categoria.length) {
        
        if(productosCategoriaDos){
           break
        }

        const codigoSicoi = categoria[index].IdentificacionSicoi
        const productosCategorias = await get_ProductosByCategoria_PerPage('0',codigoSicoi)

        if(productosCategorias){
            const filterProductos = productosCategorias.filter(unProducto => parseFloat(unProducto.Precio) > 0 && unProducto.Activo === true)
            if(filterProductos.length > 4){
                if(!nameCategoriaUno){
                    nameCategoriaUno = categoria[index].Nombre
                    productosCategoriaUno = filterProductos.slice(0,8)
                }else{
                    nameCategoriaDos = categoria[index].Nombre
                    productosCategoriaDos = filterProductos.slice(0,8)
                }

            }
        }

        index += 1
    }

    return {
        nameCategoriaUno,
        productosCategoriaUno,
        nameCategoriaDos,
        productosCategoriaDos
    }

}


export const get_DataInicio = async () => {
    let categorias
    let bannersImagenes
    let productosRelacionado
    let bannersTexto


    const searchDataCategorias = await  getAll_Categorias()
    const searchBannersImagen = await getAll_BannersImagen()
    const searchBannersTexto = await getAll_BannersTexto()
    const precioUsd = await get_ValorDolar()


    if(searchDataCategorias){
        const mezcla = searchDataCategorias.data.sort(function() { return Math.random() - 0.5 });
        const dataCategoria = mezcla.slice(0,6)
        categorias = dataCategoria

        productosRelacionado = await searchProductosCategorias(mezcla)
    }

    if(searchBannersImagen){
        bannersImagenes = searchBannersImagen.data
    }

    if(searchBannersTexto){
        bannersTexto = searchBannersTexto.data
    }

    return {
        Categorias: (categorias) ? categorias : null,
        BannersImagen: (bannersImagenes) ? bannersImagenes : null,
        Productos: productosRelacionado,
        BannersTexto: (bannersTexto) ? bannersTexto : null,
        precioUsd
    }
        
    
}