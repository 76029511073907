import React from 'react';

import { ContentTituloPrincipal, TituloPrincipalStyles, TituloBannerPrincipalStyles, SubTituloBannerPrincipalStyles, ContentTituloSeccion } from "./Estilos";

export const TituloBannerInicio = ({titulo, subtitulo}) => {
    return (
        <ContentTituloPrincipal>
            <div className="row col-md-10 mx-auto">
                <TituloBannerPrincipalStyles>
                    {titulo}
                </TituloBannerPrincipalStyles>
                <SubTituloBannerPrincipalStyles>
                    {subtitulo}
                </SubTituloBannerPrincipalStyles>

            </div>
        </ContentTituloPrincipal>
    )
}


export const TituloPrincipal = ({texto}) => {
    return (
        <ContentTituloPrincipal>
            <TituloPrincipalStyles>
                {texto}
            </TituloPrincipalStyles>
        </ContentTituloPrincipal>
    );
}


export const TituloSeccion = ({texto}) => {
    return (
        <ContentTituloSeccion>
            <p>{texto}</p>
        </ContentTituloSeccion>
    )
}


