import React, {useState} from 'react';
import { recuperar_Password } from "../../services/Users";

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import {
    ContentCardLoginForm,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
    ContentMsgError,
    ErrorValueInput,
    TenesCuenta,
} from "./Estilos";


import { RedSubmitBoton, BotonPrimarioFondoBlanco } from "../Botones/Botones";
import Loading from "../Loading/Loading";
import { ModalInfoUsuario } from "../Modals/Modales";

const RecuperaPassForm = () => {
    const [usuario, setUsuario] = useState('')
    const [errorPeticion, setErrorPeticion] = useState('')
    const [errorUser, setErrorUser] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalExito, setModalExito] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = false
        
        setErrorUser('')
        
        if(usuario === ''){
            setErrorUser('Este campo no puede estar vacio.')
            errors = true
        }

        if(errors) return
        setLoading(true)
        const result = await recuperar_Password(usuario)
        setLoading(false)

        if(result.status === 'OK'){
            setModalExito(true)
        }

        if(result.status === 'ERROR'){
            setErrorPeticion(result.msg)
        }

    }

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-6 col-12 mx-auto">
                    <ContentCardLoginForm>
                        <div className="row mb-2">
                            <div className="col-md-5">
                                <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                                <CapsulaEcommerce>
                                    <p>E-Commerce</p>
                                </CapsulaEcommerce>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <BienvenidoText>
                                    Recuperar contraseña.
                                </BienvenidoText>
                                <SubTituloBienvenido>
                                  Para recuperar tu contraseña, ingresa el mail asociado a tu cuenta y te enviaremos las instrucciones para reestablecer la misma.
                                </SubTituloBienvenido>
                            </div>
                        </div>
                        { errorPeticion === '' ?
                            null
                            :
                            <div className="row">
                                <div className="col-md-8 mx-auto text-center">
                                    <ContentMsgError>
                                        <ErrorValueInput>
                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errorPeticion}
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                </div>
                            </div>
                        }


                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12 mt-2 mb-4">
                                    <LabelForm>
                                        Usuario
                                    </LabelForm>

                                    <input
                                        type="text"
                                        name='usuario'
                                        id='usuario'
                                        className='Rueda_InputInicio'
                                        placeholder='Ingresá tu correo electronico'
                                        value={usuario}
                                        onChange={(e)=>setUsuario(e.target.value)}
                                    />
                                    { errorUser === '' ?
                                        null
                                        :
                                        <ContentMsgError>
                                            <ErrorValueInput>
                                                <i className='bi bi-exclamation-triangle p-1' ></i>  {errorUser}
                                            </ErrorValueInput>
                                        </ContentMsgError>
                                    }
      
                                </div>



                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <RedSubmitBoton texto='Recuperar contraseña' />
                                </div>
                            </div>
                        </form>
                        
                        <div className="row mt-3">
                            <div className="col-md-4 align-self-center">
                                <hr />
                            </div>
                            <div className="col-md-4 text-center">
                                <TenesCuenta>
                                    No tenés cuenta
                                </TenesCuenta>
                            </div>
                            <div className="col-md-4 align-self-center">
                                <hr />
                            </div>
                            <div className="col-md-3 mx-auto">
                                <BotonPrimarioFondoBlanco
                                    link='/Registrarme'
                                    texto='Registrate'
                                />
                            </div>
                        </div>


                    </ContentCardLoginForm>
                </div>
            </div>

            <ModalInfoUsuario 
                data={modalExito}
                titulo='Solicitud recibida.'
                texto='Te hemos enviado un correo con las instrucciones para recuperar tu contraseña.'
            />
        </>

    );
}



export default RecuperaPassForm;