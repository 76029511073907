import React, { useState, useContext, useEffect, Suspense } from 'react';
import { get_PerfilUsuario } from "../services/Users";
import UserContext from "../context/userContext";

import Loading from "../components/Loading/Loading";
import { TituloPrincipal } from "../components/Titulos/Titulos";
import UserPerfilForm from "../components/CardsUser/UserPerfilForm";


const UserPerfil = () => {

    const [loading, setLoading] = useState(false)
    const [perfil, setPerfil] = useState(null)
    
    let context = useContext(UserContext)

    useEffect(() => {
        const data = async () => {
            setLoading(true)
            if (!context.jwt) {
                window.location.href = '/Ingresar'
            }else{
                const result = await get_PerfilUsuario(context.jwt.token)
                if(result.data.Perfil){
                    setPerfil(result.data.Perfil)
                }
            }

           


            setLoading(false)

        }
        data()

    }, [])


    return (
        <>
            {
                loading ?
                    <Loading /> :
                    <Suspense fallback={<Loading />}>

                        <TituloPrincipal texto={`Edita tu perfil`} />

                        <div className="row mt-4 mb-4">
                            <div className="col-md-6 mx-auto">

                                <UserPerfilForm perfil={perfil} token={context.jwt}  />


                            </div>
                        </div>


                    </Suspense>

            }
        </>


    );
}



export default UserPerfil;