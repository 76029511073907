import React, {useState, useEffect, useContext} from 'react';
import UserContext from "../context/userContext";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import Loading from "../components/Loading/Loading";

import SinDatos from "../components/Errors/SinDatos";
import CardsCategorias from "../components/CardsCategorias/CardsCategorias";


const Categorias = () => {

    const [loading, setLoading] = useState(false)
    const [categorias, setCategorias] = useState([])

    const context = useContext(UserContext)

    useEffect(()=>{
        const getData = async()=> {
            setLoading(true)
            if(context.categorias){
                setCategorias(context.categorias)
            }else{
                setCategorias(null)
            }
            setLoading(false)
        }

        getData()
    },[])

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <TituloPrincipal texto='Explora nuestras categorias' />

            <div className="mt-4 mb-4">
                {
                    categorias ? 
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="row">
                                {
                                    categorias.map(unaCategoria => (
                                        <CardsCategorias categoria={unaCategoria} key={unaCategoria.Id} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    :
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <SinDatos 
                                title='Lo sentimos'
                                message='Aún no tenemos Categorias para mostrarte'
                            />

                        </div>
                    </div>
                }

            </div>
        </>
    ) 
}



export default Categorias;