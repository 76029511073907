import React from 'react';

import {TituloPrincipal  } from "../components/Titulos/Titulos";
import CardsAyuda from "../components/CardsAyuda/CardsAyuda";

const preguntas = [
    {
        Id: 1,
        Pregunta: '¿Cómo comprar en Rueda Digital?',
        Respuesta: 'Comprar en Rueda Digital es muy fácil y seguro. Para poder comprar nuestros productos es excluyente que te registres como cliente.',
        Parrafo1: 'Recordá que también podemos despejar tus dudas en nuestro Centro de Atención al Cliente.'
    },
    {
        Id: 2,
        Pregunta: '¿Cómo registrarme en Rueda Digital?',
        Respuesta: 'Para registrarte en Rueda Digital seguí los siguientes pasos:',
        Items: [
            {
                Id: 1,
                Item: 'Crea tu cuenta haciendo click en "Registrate" en la barra de navegación.'
            },
            {
                Id: 2,
                Item: 'Completa los campos, y finalizá clickeando en "Registrarme".'
            },
            {
                Id: 3,
                Item: 'Te llegara un mail a tu casilla de correo un link para verificar tu cuenta.'
            },
            {
                Id: 4,
                Item: 'Hace click en el link, y luego ingresá con tu cuenta de Rueda Digital. '
            },
        ]
    },
    {
        Id: 3,
        Pregunta: '¿Cuales son los plazos y costos de entrega?',
        Respuesta: 'Los productos comprados se retiran en nuestra sucursal y el plazo de entrega esta detallado en tu Orden de Compra.'
    },
    {
        Id: 4,
        Pregunta: 'Olvide mi contraseña',
        Respuesta: 'Dentro de la pantalla de Inicio de Sesión, hace click en "Olvidaste tu contraseña".',
        Parrafo1: "Ingresá a tu casilla de correo para ver el link que te envíamos. Luego clicker en este link, seguí el formulario para cambiar tu contraseña."
    },
    {
        Id: 5,
        Pregunta: '¿Cómo recibo mi factura de compra?',
        Respuesta: 'Una vez que retiras tu pedido, te entregaremos en físico tu factura de compra junto con el pedido.',
       
    }

]

const Ayuda = () => {
    return (
        <>
            <TituloPrincipal texto='Ayuda' />

            <CardsAyuda preguntas={preguntas} />

        </>
    ) 
}



export default Ayuda;