import React, { useState, useEffect } from 'react';

import { get_CondiocionesIVA, edit_PerfilUsuario } from "../../services/Users";

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import Loading from "../Loading/Loading";
import { RedSubmitBoton } from "../Botones/Botones";

import {
    ContentCardPrincipal,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
    ContentMsgError,
    ErrorValueInput
} from "./Estilos";


import { ModalInfoUsuario } from "../Modals/Modales";

const UserPerfilForm = ({ perfil, token }) => {

    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [errorPerfil, setErrorPerfil] = useState('')

    const [condicionesIva, setCondicionesIva] = useState([])

    const [razonSocial, setRazonSocial] = useState(`${perfil ? `${perfil.RazonSocial}` : ''}`)
    const [errorRazonSocial, setErrorRazonSocial] = useState('')

    const [cuil, setCuil] = useState(`${perfil ? `${perfil.CUIL}` : ''}`)
    const [errorCuil, setErrorCuil] = useState('')

    const [condicion, setCondicion] = useState(`${perfil ? `${perfil.CondicionFrenteAlIVA.Id}` : ''}`)
    const [errorCondicion, setErrorCondicion] = useState('')

    const [domicilio, setDomicilio] = useState(`${perfil ? `${perfil.Domicilio}` : ''}`)
    const [errorDomicilio, setErrorDomicilio] = useState('')


    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const data = await get_CondiocionesIVA()
            if (data) {
                setCondicionesIva(data)
            }
            setLoading(false)
        }

        getData()
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();

        let errores = false
        const campoObligatorio = 'Este campo es Obligatorio'


        if (razonSocial === '') {
            setErrorRazonSocial(campoObligatorio)
            errores = true
        }

        if (cuil === '') {
            setErrorCuil(campoObligatorio)
            errores = true
        }

        if (condicion === '') {
            setErrorCondicion(campoObligatorio)
            errores = true
        }

        if (domicilio === '') {
            setErrorDomicilio(campoObligatorio)
            errores = true
        }

        if (errores === true) return

        setLoading(true)
        const body = {
            idCondicionFrenteAlIVA: condicion,
            RazonSocial: razonSocial,
            CUIL: cuil,
            Domicilio: domicilio
        }

        const result = await edit_PerfilUsuario(token.token, body)

        if(!result){
            setErrorPerfil('Ah ocurrido un error. Por favor vuelvo a intentar y en caso de persistis el error por favor comunicate con nostros')
        }else{
            setModal(true)
        }
        setLoading(false)
        return


    }

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <ContentCardPrincipal>
                            <div className="row mb-2">
                                <div className="col-md-5">
                                    <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                                    <CapsulaEcommerce>
                                        <p>E-Commerce</p>
                                    </CapsulaEcommerce>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <BienvenidoText>
                                        Edita tu perfil
                                    </BienvenidoText>
                                    <SubTituloBienvenido>
                                        Carga o edita los datos de tu perfil, para que puedas utilizar nuestra plataforma de forma correcta.
                                    </SubTituloBienvenido>
                                </div>
                            </div>


                            { errorPerfil === '' ?
                            null
                            :
                            <div className="row">
                                <div className="col-md-8 mx-auto text-center">
                                    <ContentMsgError>
                                        <ErrorValueInput>
                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errorPerfil}
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                </div>
                            </div>
                            }
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="row">


                                    <div className="col-md-12 mt-2 mb-4">
                                        <LabelForm htmlFor='RazonSocial'>
                                            Razon Social
                                        </LabelForm>

                                        <input
                                            type="text"
                                            className='Rueda_InputInicio'
                                            id='RazonSocial'
                                            placeholder={perfil ? 'Existe Perfil' : 'Ingresa tu Razón Social.'}
                                            value={razonSocial}
                                            onChange={(e) => setRazonSocial(e.target.value)}

                                        />
                                        {errorRazonSocial === '' ?
                                            null
                                            :
                                            <ContentMsgError>
                                                <ErrorValueInput>
                                                    <i className='bi bi-exclamation-triangle p-1' ></i> {errorRazonSocial}
                                                </ErrorValueInput>
                                            </ContentMsgError>
                                        }

                                    </div>

                                    <div className="col-md-6 mt-2-mb-4">
                                        <LabelForm htmlFor='cuil'>
                                            N° CUIL / CUIT
                                        </LabelForm>

                                        <input
                                            type="text"
                                            className='Rueda_InputInicio'
                                            id='cuil'
                                            placeholder={perfil ? 'Existe Perfil' : 'Ingresa tu numero de CUIT.'}
                                            value={cuil}
                                            onChange={(e) => setCuil(e.target.value)}

                                        />
                                        {errorCuil === '' ?
                                            null
                                            :
                                            <ContentMsgError>
                                                <ErrorValueInput>
                                                    <i className='bi bi-exclamation-triangle p-1' ></i> {errorCuil}
                                                </ErrorValueInput>
                                            </ContentMsgError>
                                        }

                                    </div>

                                    <div className="col-md-6 mt-2-mb-4">
                                        <LabelForm htmlFor='condicion'>
                                            Condicion Frente al Iva
                                        </LabelForm>

                                        <select className="Rueda_InputInicio" aria-label="Default select example"
                                            onChange={(e) => setCondicion(e.target.value)}
                                            value={condicion}
                                        >
                                            <option>Seleciona una opcion</option>
                                            {
                                                condicionesIva ?
                                                    <>
                                                        {
                                                            condicionesIva.map(unaCondicion => (
                                                                <option value={unaCondicion.Id} key={unaCondicion.Id}>{unaCondicion.CondicionFrenteAlIva}</option>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }

                                        </select>

                                        {errorCondicion === '' ?
                                            null
                                            :
                                            <ContentMsgError>
                                                <ErrorValueInput>
                                                    <i className='bi bi-exclamation-triangle p-1' ></i> {errorCondicion}
                                                </ErrorValueInput>
                                            </ContentMsgError>
                                        }

                                    </div>


                                    <div className="col-md-12 mt-2 mb-4">
                                        <LabelForm htmlFor='Domicilio'>
                                            Domicilio
                                        </LabelForm>

                                        <input
                                            type="text"
                                            className='Rueda_InputInicio'
                                            id='Domicilio'
                                            placeholder={perfil ? 'Existe Perfil' : 'Ingresa tu Domicilio.'}
                                            value={domicilio}
                                            onChange={(e) => setDomicilio(e.target.value)}

                                        />
                                        {errorDomicilio === '' ?
                                            null
                                            :
                                            <ContentMsgError>
                                                <ErrorValueInput>
                                                    <i className='bi bi-exclamation-triangle p-1' ></i> {errorDomicilio}
                                                </ErrorValueInput>
                                            </ContentMsgError>
                                        }

                                    </div>



                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <RedSubmitBoton texto='Actualizar mis datos' />
                                    </div>
                                </div>
                            </form>
                        </ContentCardPrincipal>

                        <ModalInfoUsuario 
                            data={modal}
                            titulo='Solicitud recibida.'
                            texto='Hemos recibido los datos de tu perfil, en breve los estaremos revisando para que puedas operar a traves de nuestra plataforma.'
                        
                        />
                    </>

            }


        </>

    )
}



export default UserPerfilForm;