import React, {useState} from 'react';
import { cambiar_Password } from "../../services/Users";


import LogoRuedaImg from "../../assets/Logos/logo.svg";

import {
    ContentCardLoginForm,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
    ContentMsgError,
    ErrorValueInput,
    TenesCuenta,
} from "./Estilos";


import { RedSubmitBoton, BotonPrimarioFondoBlanco } from "../Botones/Botones";
import Loading from "../Loading/Loading";
import { ModalInfoUsuario } from "../Modals/Modales";

const CambiarPassForm = ({mail, hash}) => {
    const [newContrasena, setNewContraseña] = useState('')
    const [repitContrasena, setRepitContrasena] = useState('')

    const [errorPeticion, setErrorPeticion] = useState('')

    const [errorContrasena, setErrorContrasena] = useState('')

    const [loading, setLoading] = useState(false)
    const [modalExito, setModalExito] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorContrasena('')
        
        if(newContrasena === '' || repitContrasena === '' ){
            setErrorContrasena('Ambos campos son obligatorios.')
            return
        }

        if(newContrasena.length < 6){
            setErrorContrasena('Tu nueva contraseña debe contener al menos 6 caracteres.')
            return
        }

        if(newContrasena !== repitContrasena){
            setErrorContrasena('Las nuevas contraseñas no coinciden.')
            return
        }

        setLoading(true)

        const body = {
            CorreoElectronico: mail,
            HashForgot: hash,
            NewPassword: newContrasena
        }

        const result = await cambiar_Password(body, mail)

        setLoading(false)
        if(result.status === 'OK'){
            setModalExito(true)
        }

        if(result.status === 'ERROR'){
            setErrorPeticion(result.msg)
        }
        


    }

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-6 col-12 mx-auto">
                    <ContentCardLoginForm>
                        <div className="row mb-2">
                            <div className="col-md-5">
                                <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                                <CapsulaEcommerce>
                                    <p>E-Commerce</p>
                                </CapsulaEcommerce>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <BienvenidoText>
                                    Recuperar contraseña.
                                </BienvenidoText>
                                <SubTituloBienvenido>
                                  Para recuperar tu contraseña, ingresa el mail asociado a tu cuenta y te enviaremos las instrucciones para reestablecer la misma.
                                </SubTituloBienvenido>
                            </div>
                        </div>
                        { errorPeticion === '' ?
                            null
                            :
                            <div className="row">
                                <div className="col-md-8 mx-auto text-center">
                                    <ContentMsgError>
                                        <ErrorValueInput>
                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errorPeticion}
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                </div>
                            </div>
                        }


                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12 mt-2 mb-4">
                                    <LabelForm>
                                        Ingresa tu nueva contraseña
                                    </LabelForm>

                                    <input
                                        type="text"
                                        className='Rueda_InputInicio'
                                        placeholder='Ingresá tu nueva contraseña'
                                        value={newContrasena}
                                        onChange={(e)=>setNewContraseña(e.target.value)}
                                    />
      
                                </div>
                                <div className="col-md-12 mt-2 mb-4">
                                    <LabelForm>
                                        Repite la nueva contraseña
                                    </LabelForm>

                                    <input
                                        type="text"
                                        className='Rueda_InputInicio'
                                        placeholder='Repite tu nueva contraseña'
                                        value={repitContrasena}
                                        onChange={(e)=>setRepitContrasena(e.target.value)}
                                    />
                                    { errorContrasena === '' ?
                                        null
                                        :
                                        <ContentMsgError>
                                            <ErrorValueInput>
                                                <i className='bi bi-exclamation-triangle p-1' ></i>  {errorContrasena}
                                            </ErrorValueInput>
                                        </ContentMsgError>
                                    }
      
                                </div>



                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <RedSubmitBoton texto='Recuperar contraseña' />
                                </div>
                            </div>
                        </form>
                        
                        <div className="row mt-3">
                            <div className="col-md-4 align-self-center">
                                <hr />
                            </div>
                            <div className="col-md-4 text-center">
                                <TenesCuenta>
                                    No tenés cuenta
                                </TenesCuenta>
                            </div>
                            <div className="col-md-4 align-self-center">
                                <hr />
                            </div>
                            <div className="col-md-3 mx-auto">
                                <BotonPrimarioFondoBlanco
                                    link='/Registrarme'
                                    texto='Registrate'
                                />
                            </div>
                        </div>


                    </ContentCardLoginForm>
                </div>
            </div>

            <ModalInfoUsuario 
                data={modalExito}
                titulo='Contraseña reestablecida.'
                texto='Tu contraseña fue reestablecida de forma correcta. '
                login={true}
            />
        </>

    );
}



export default CambiarPassForm;