import React from 'react';

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import { 
    ContentCardLoginForm,    
    ImgLogoRueda,
    CapsulaEcommerce, 
    BienvenidoText,
    SubTituloBienvenido
} from "./Estilos";

import { BotonPrimarioFondoRojo, BotonPrimarioFondoBlanco } from "../Botones/Botones";

export const IsInValidUser = () => {
    return (

        <div className='row mt-4'>
            <div className="col-md-6 mx-auto">
                <ContentCardLoginForm>
                    <div className="row mb-2">
                        <div className="col-md-5">
                            <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                            <CapsulaEcommerce>
                                <p>E-Commerce</p>
                            </CapsulaEcommerce>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12">
                            <BienvenidoText>
                                Lo sentimos.
                            </BienvenidoText>
                            <hr />
                            <SubTituloBienvenido>
                                A ocurrido un error al intentar validar tu correo electronico. <br />
                                Por favor vuelvelo a intentar haciendo click en el enlace que te enviamos por correo electronico. <br /> <br />
                                <span>En caso de que consideres este mensaje un error por favor comunicate con nostros por mail.</span>
                                    
                            </SubTituloBienvenido>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <BotonPrimarioFondoRojo 
                                link='/Contacto'
                                texto='Contactanos'
                            />
                        </div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoBlanco 
                                link='/'
                                texto='Ir al inicio'
                            />

                        </div>
                    </div>

                </ContentCardLoginForm>
            </div>
        </div>
    )
}

export const IsValidUser = () => {
    return (

        <div className='row mt-4'>
            <div className="col-md-6 mx-auto">
                <ContentCardLoginForm>
                    <div className="row mb-2">
                        <div className="col-md-5">
                            <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                            <CapsulaEcommerce>
                                <p>E-Commerce</p>
                            </CapsulaEcommerce>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12">
                            <BienvenidoText>
                                ¡Mail Validado!
                            </BienvenidoText>
                            <hr />
                            <SubTituloBienvenido>
                                Gracias por elegirnos. <br /> Tu cuenta ya ha sido verificada de forma correcta. <br />
                            </SubTituloBienvenido>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <BotonPrimarioFondoRojo 
                                link='/Ingresar'
                                texto='Iniciar Sesión'
                            />
                        </div>
                        <div className="col-md-4">
                            <BotonPrimarioFondoBlanco 
                                link='/'
                                texto='Ir al inicio'
                            />

                        </div>
                    </div>

                </ContentCardLoginForm>
            </div>
        </div>
    )
}