import React from 'react';
import { Link } from 'react-router-dom';

import { ContentButton, RedPrimaryButtonStyles, WhitePrimaryButtonStyles}  from "./Estilos";

export const BotonPrimarioFondoRojo = ({link, texto}) =>{
  
        return (
            <ContentButton>
                <Link to={link}>
                    <RedPrimaryButtonStyles>
                        {texto}
                    </RedPrimaryButtonStyles>
                </Link>
            </ContentButton>
    
        )
    
}

export const BotonPrimarioFondoBlanco = ({link, texto}) =>{
  
    return (
        <ContentButton>
            <Link to={link}>
                <WhitePrimaryButtonStyles>
                    {texto}
                </WhitePrimaryButtonStyles>
            </Link>
        </ContentButton>

    )

}

export const RedSubmitBoton = ({texto}) => {
    return(
        <ContentButton>
            <RedPrimaryButtonStyles type='submit'>
                {texto}
            </RedPrimaryButtonStyles>
        </ContentButton>
    )
}

export const IrAlInicioBotonFondoRojo = ({link, texto}) =>{
  
    return (
        <ContentButton>
            <Link to={link}>
                <RedPrimaryButtonStyles>
                    {texto}
                </RedPrimaryButtonStyles>
            </Link>
        </ContentButton>

    )

}

export const ReturnRedButton = () => {

    const historyBack = () => {
        window.history.back()
    }

    return (
        <ContentButton>
            <RedPrimaryButtonStyles type='button' onClick={()=> historyBack()}>
            Volver atras
            </RedPrimaryButtonStyles>
        </ContentButton>

    )
}

export const AgregarAlCarrito = ({funcion, parametro}) => {
    return (
        <ContentButton>
            <RedPrimaryButtonStyles type='button' onClick={()=>funcion(parametro)}>
                <i className='bi bi-cart'>    </i>
                Agregar al Carrito
            </RedPrimaryButtonStyles>

        </ContentButton>
    )
}