import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";


import { get_ProductoBySicoi } from "../services/Productos";
import { get_ValorDolar } from "../services/CotizacionDolar";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import Loading from "../components/Loading/Loading";

import SinDatos from "../components/Errors/SinDatos";
import UnProducto from "../components/CardsProductos/UnProducto";

const VerUnProducto = () => {
    const {sicoi} = useParams()
    const [loading, setLoading] = useState(true)
    const [producto, setProducto] = useState(null)
    const [precioUDS, setPrecioUDS] = useState()

    useEffect(()=> {
        const getData = async(sicoi) => {
            const dolar = await get_ValorDolar()
            if(dolar){
                setPrecioUDS(dolar)
                const result = await get_ProductoBySicoi(sicoi)
                if(result){
                    setProducto(result)
                }
            }else{
                setProducto(null)
            }

            setLoading(false)
        }

        getData(sicoi)
    },[sicoi])

    if(loading){
        return (
            <Loading />
        )
    }



    return (
        <>

            { producto ?
                <>
                    <TituloPrincipal texto={producto.Nombre}  />

                    <UnProducto producto={producto} precioUSD={precioUDS} />

                    
                </>
                :
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <SinDatos 
                                title='Lo sentimos'
                                message='Al parecer lo que estas buscando ya no se encuentra disponible'
                            />

                        </div>
                    </div>
                </div>

            }

        </>
    )
}

export default VerUnProducto