import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const get_StatusApi = async() => {
    try {
        const result = await axios({
            method:'GET',
            url: `${EndPointApi}/statusApi`,
            auth: Credencials
        })

        if(result){
            if(result.data.status === 'OK'){
                return true
            }else{
                return null
            }
        }
        
        return null
        
    } catch (error) {
        return null
    }
}