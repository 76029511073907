import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { send_Contacto } from "../../services/Contacto";

import SupImg from "../../assets/Images/ruedasDigital_contacto.png";
import { 
    ContentCardContactForm,
    SubtitulosContactForm,
    ImagenSuperiorContactForm,
    DataContacto,
    LabelForm,
    ContentMsgError,
    ErrorValueInput

} from "./Estilos";

import Loading from "../Loading/Loading";

import { RedSubmitBoton } from "../Botones/Botones";

import { ModalErrorContacto, ModalExito } from "../Modals/Modales";

const ContactForm = () => {

    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)
    const [loading, setLoading] = useState(false)

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-6 col-12 mx-auto">
                    <ContentCardContactForm>
                        <div className="row mb-4">
                            <div className="col-md-6 col-6 align-self-center">
                                <SubtitulosContactForm>
                                 ¿Tenés dudas para despejar con nuestros representantes?
                                </SubtitulosContactForm>
                            </div>
                            <div className="col-md-6 col-6 align-self-center">
                                <ImagenSuperiorContactForm src={SupImg} alt="Rueda Digital - Imagen Contacto" />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-10 mx-auto">
                                <DataContacto>
                                    <i className="bi bi-envelope-fill"></i>  info@ruedasdigital.com.ar
                                </DataContacto>
                            </div>
                            <div className="col-md-10 mx-auto">
                                <DataContacto>
                                    <i className="bi bi-geo-fill"></i>  Atanasio Girardot 1449, CABA
                                </DataContacto>
                            </div>
                        </div>

                        <div className="row">
                            <Formik
                                initialValues={{
                                    nombre:'',
                                    mail: '',
                                    telefono: '',
                                    mensaje: ''

                                }}

                                validate={(valores) => {

                                    const msgCampoObligatorio = 'Este campo es obligatorio'
                                    const msgSoloLetras = 'El dato indicado no es valido'
                                
                                    const expresionLetras = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
                                    const expresionMail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                                    let errores = {}

                                    if(!valores.nombre){
                                        errores.nombre = msgCampoObligatorio
                                    }else if(!expresionLetras.test(valores.nombre)){
                                        errores.nombre = msgSoloLetras
                                    }

                                    if(!valores.mail){
                                        errores.mail = msgCampoObligatorio  
                                    }else if(!expresionMail.test(valores.mail)){
                                        errores.mail = 'El correo indicado no es valido'
                                    }

                                    if(!valores.telefono){
                                        errores.telefono = msgCampoObligatorio
                                    }

                                    if(!valores.mensaje){
                                        errores.mensaje = msgCampoObligatorio
                                    }

                                    return errores
                                }}

                                onSubmit={async(valores) => {

                                    setLoading(true)
                                    const body = {
                                        De: valores.nombre,
                                        Mail: valores.mail,
                                        Telefono: valores.telefono,
                                        Mensaje: valores.mensaje
                                    }

                                    const result = await send_Contacto(body)

                                    if(result){
                                        setModalExito(true)
                                    }else{
                                        setModalError(true)
                                    }

                                    setLoading(false)
                                }}
                            
                            >
                                {
                                    ({errors}) => (
                                        <Form>
                                            <div className="col-md-10 mx-auto mt-2 mb-4">
                                                <LabelForm htmlFor='nombre'>
                                                    Nombre y Apellido
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='nombre'
                                                    id='nombre'
                                                    className='Rueda_InputContacto'
                                                    placeholder='Ingresá tu nombre y apellido'
                                                />

                                                <ErrorMessage name='nombre' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errors.nombre}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-10 mx-auto mt-2 mb-4">
                                                <LabelForm htmlFor='mail'>
                                                    Correo Electronico
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='mail'
                                                    id='mail'
                                                    className='Rueda_InputContacto'
                                                    placeholder='Ingresá tu correo electronico.'
                                                />

                                                <ErrorMessage name='mail' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errors.mail}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-10 mx-auto mt-2 mb-4">
                                                <LabelForm htmlFor='telefono'>
                                                    Telefono de contacto
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='telefono'
                                                    id='telefono'
                                                    className='Rueda_InputContacto'
                                                    placeholder='Ingresá tu número de contacto.'
                                                />

                                                <ErrorMessage name='telefono' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errors.telefono}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-10 mx-auto mt-2 mb-4">
                                                <LabelForm htmlFor='mensaje'>
                                                    Mensaje
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='mensaje'
                                                    id='mensaje'
                                                    className='Rueda_InputContacto'
                                                    placeholder='Escribe tu mensaje.'
                                                    as='textarea'
                                                    rows={5}
                                                />

                                                <ErrorMessage name='mensaje' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errors.mensaje}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>


                                            <div className="row">
                                                <div className="col-md-6 mx-auto">
                                                    <RedSubmitBoton texto='Enviar consulta' />
                                                </div>
                                            </div>

                                            

                                        </Form>
                                    )
                                }



                            </Formik>


                        </div>
                    </ContentCardContactForm>

                </div>

            </div>

            <ModalExito 
                data={modalExito}
                icono='bi bi-envelope'
                titulo='Mensaje Recibido'
                message='Hemos recibido tu mensaje, en breve nos estaremos comunicando con vos.'
            />

            <ModalErrorContacto
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups ...'
                message='Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
            />
        </>
    )
}



export default ContactForm;