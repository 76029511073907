import styled, {keyframes} from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;



export const ContentCardContactForm = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 50px;

    @media (max-width: 480px) {
        width: 95%;
        margin: auto;
    }

`

export const SubtitulosContactForm = styled.p`
    font-family: var(--Font-Roboto-Black);
    color: var(--Color-Negro);
    font-size: 20px;
    margin-bottom: 0;
    @media (max-width: 480px) {
        font-size: 14px;
    }
`

export const ImagenSuperiorContactForm = styled.img `
    width: 90%;
    margin-left: 5%;
`

export const DataContacto = styled.p `
    color: var(--Color-Red-Primary);
    font-family: var(--Font-Roboto-Regular);
    font-size: 16px;
    text-align: center;

    & > i{
        padding-right: 5px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }
`


export const LabelForm = styled.label`
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
    width: 90%;
    margin-left: 2%;

    @media (max-width: 480px) {
        font-size: 10px;
    }
`

export const ContentMsgError = styled.div`
    animation: ${opacity} 1s ease;
    position: relative;
`

export const ErrorValueInput = styled.span`
    color: var(--Color-Red-Primary);
    font-family:var(--Font-DMSans-Regular);
    margin-left: 2%;
    font-weight:bold;
    font-size: 12px;

    @media (max-width: 480px) {
        font-size: 10px;
    }
    
`

