import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const send_Contacto = async(body) => {
    try {
        const {data} = await axios({
            method:'POST',
            url:`${EndPointApi}/addContacto`,
            auth: Credencials,
            data: body
        })

        if(data.status === 'OK'){
            return data
        }

        return null


        
    } catch (error) {
        return null
    }
}