import React, {useState} from 'react';
import { useSearchParams } from "react-router-dom";

import { MessageError } from './Estilos'

const Busqueda = () => {
    const [searchParams] = useSearchParams()
    const type = searchParams.get('search')
    const [busqueda, setBusqueda] = useState(type ? type : '')
    const [error, setError] = useState('')

    const HandleSubmit = (e) => {
        e.preventDefault()
        setError('')
        if(busqueda.length < 4){
            setError('Debes indicar al menos 4 caracteres.')
            return
        }

        if(busqueda === type){
            return
        }

        if(busqueda.length >=4){
            const expresionLetras = /^[a-zA-ZÀ-ÿ\s]{1,40}$/
            if(!expresionLetras.test(busqueda)){
                setError('La busqueda solo puede contener letras')
                return
            }else{
                window.location.replace(`/Busquedas?search=${busqueda}`)
                return
            }
        }
    }


        return (
            <div className="row mt-4 mb-4">
                <div className="col-md-8 col-10 mx-auto">
                    <form action="">
                        <div className="input-group mb-3">
                            <div className="form-floating content_input_busqueda">
                                <input 
                                    type="text" 
                                    className="form-control input_busqueda" 
                                    id="input_busqueda"
                                    placeholder="Buscar un producto" 
                                    value={busqueda}
                                    onChange={(e)=>setBusqueda(e.target.value)}
                                />
                                <label htmlFor="input_busqueda" className='label_busqueda'><i className="bi bi-search"></i> Buscar un producto</label>
        
        
                            </div>
        
                            <button 
                                className='input-group-text button_busqueda btn'
                                type="submit"
                                onClick={HandleSubmit}
                            >
                                <i className="bi bi-search"></i> Buscar
                            </button>
        
                        </div>
                    </form>
                    
                    { error === '' ? <br />:<MessageError>{error} </MessageError>} 
                        
                    
    
                </div>
            </div>
        )


}

export default Busqueda