import React, { useState, useContext } from 'react';
import UserContext from "../../context/userContext";
import { edita_CarritoUsuario, generaOrden } from "../../services/Users";



import { generaPrecioTotal } from "../../helpers/generaPrecios";
import { ContentItemCarrito, TituloTotalCarrito, PrecioTotalCarrito, DeleteItemDelCarrito as VaciarCarrito, ConfirmCarritoCarrito } from "./Estilos";

import Loading from "../Loading/Loading";
import { ModalProductoAgregado as Modal, ModalOrdenAgregada } from "../Modals/Modales";

const ShowPrecioTotalCarrito = ({ precioTotal }) => {
    const splitNumero = precioTotal.split(',')

    return (
        <PrecioTotalCarrito>
            ARS $ {splitNumero[0]},
            <sup>{splitNumero[1]}</sup>
        </PrecioTotalCarrito>
    )
}

const CardsTotalCarrito = ({ productos, precioUds, token }) => {
    const context = useContext(UserContext)
    const valorPrecioTotal = generaPrecioTotal(productos, precioUds)
    const [loading, setLoading] = useState(false)
    const [modalOrdenAgregada, setModalOrdenAgregada] = useState(false)
    const [modal, setModal] = useState(false)
    const [titulo, setTitulo] = useState('')
    const [msg, setMsg] = useState('')
    const [errorOperacion, setErrorOperacion] = useState(false)
    const [link, setLink] = useState('')
    const [texto, setTexto] = useState('')


    const vaciaCarrito = async() => {
        const confirmVaciaCarrito = window.confirm('Estas a punto de vaciar tu carrito. ¿Deseas continuar?')

        if(confirmVaciaCarrito){
            setLoading(true)
            const body = {Carrito:[]}
            const result = await edita_CarritoUsuario(token, body)
            if(result){
                setTitulo('Todo ha ido bien.')
                setMsg('Hemos vaciado tu carrito de forma correcta.')
                setErrorOperacion(true)
                setModal(true)
            }else{
                setTitulo('Ups.')
                setMsg('Ah ocurrido al intentar procesar tu solicitud.')
                setErrorOperacion(true)
                setLink('')
                setTexto('')
                setModal(true)
            }
            context.setItems(0)
            setLoading(false)
        }

        return
    }

    const confirmOrden = async() => {

        const confirmOden = window.confirm(`Estas por generar una orden por \n ARS  $${valorPrecioTotal} \n ¿Deseas continuar?`)
        if(confirmOden){
            setLoading(true)
            const body = {
                tipoCambio: precioUds.data.venta
            }
            const result = await generaOrden(context.jwt.token, body)
            if(result.status === 'OK'){
                setModalOrdenAgregada(true)
            }else{
                setTitulo('Ups.')
                setMsg(result.msg)
                setErrorOperacion(true)
                setLink('')
                setTexto('')
                setModal(true)
            }
            context.setItems(0)
            setLoading(false)
        }
    }

    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    <hr className='mobile-none' />
                    <ContentItemCarrito>
                        <div className="row">
                            <div className="col-md-6 mx-auto text-center">
                                <TituloTotalCarrito>Total Carrito</TituloTotalCarrito>
                                <ShowPrecioTotalCarrito precioTotal={valorPrecioTotal} />
                            </div>
                        </div>
                        <hr className='mobile-none' />
                        <div className="row text-center">
                            <div className="col-md-8 mx-auto">
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <ConfirmCarritoCarrito onClick={()=>confirmOrden()}>
                                            Generar Orden
                                            <i className="bi bi-cart-check"></i>
                                        </ConfirmCarritoCarrito>

                                    </div>
                                    <div className="col-md-6 col-6">
                                        <VaciarCarrito onClick={()=>vaciaCarrito()}>
                                            Vaciar carrito
                                            <i className="bi bi-trash-fill"></i>
                                        </VaciarCarrito>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </ContentItemCarrito>

                    <Modal 
                        data={modal}
                        titulo={titulo}
                        message={msg}
                        link={link}
                        texto={texto}
                        error={errorOperacion}
                    />

                    <ModalOrdenAgregada 
                        data={modalOrdenAgregada}
                    />
                </>
            }

        </>


    );
}



export default CardsTotalCarrito;