import styled from 'styled-components';

export const ContentCardCategoria = styled.div`
    padding: 20px;
    background-color: var(--Color-Blanco);
    border-radius: 15px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    text-align: center;
    transition: all .25s linear;

    &>a{

        text-decoration: none;
        font-family: var(--Font-DMSans-Bold);
        font-size: 14px;
        color: var(--Color-Negro);
        transition: all .25s ease-in;
    }

    &:hover{
        transform: scale(0.9);

        & >a{
            color: var(--Color-Red-Primary);
            font-family: var(--Font-DMSans-Bold);
        }

    }


`

export const ImagenCategoria = styled.img`
    width: 100%;
    margin-bottom: 3%;

`

export const SpanNombreCategoria = styled.p`
    width: 100%;
    text-align: center;

    @media (max-width: 480px) {
        font-size: 12px;
    }

    

`



export const ContentNombreCategoriaInicio = styled.div`
    width: 100%;
    text-align: center;
    overflow: hidden;
    height: 20px;
    margin-top: 2%;

    & > p {
        color: var(--Color-Negro);
        font-family: var(--Font-DMSans-Regular);
        font-size: 12px;
        overflow:hidden;
        position:relative;
        margin-bottom: 0;
    }
`

export const ContentCardCategoriaInicio = styled.div`
    padding: 10px;
    background-color: var(--Color-Blanco);
    border-radius: 15px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    text-align: center;
    transition: all .25s linear;

    &>a{

        text-decoration: none;
        font-family: var(--Font-DMSans-Bold);
        font-size: 14px;
        color: var(--Color-Negro);
        transition: all .25s ease-in;
    }

    &:hover{
        transform: scale(0.9);

        & >a{
            color: var(--Color-Red-Primary);
            font-family: var(--Font-DMSans-Bold);
        }

    }


`