import React from 'react';
import { Link } from 'react-router-dom';

import { ContentItemCarrito, DeleteItemDelCarrito as BtnVerProductos, TituloTotalCarrito, PrecioTotalCarrito } from "./Estilos";

const EmptyCarrito = () => {
    return (
        <div className="row mt-4">
            <div className="col-md-6 mx-auto">
                <ContentItemCarrito>
                    <div className="row">
                        <div className="col-md-8 mx-auto text-center">
                            <PrecioTotalCarrito>
                                Tu carrito esta vacio.
                            </PrecioTotalCarrito>
                            <TituloTotalCarrito>
                               Mira nuestra sección de productos.
                            </TituloTotalCarrito>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6 mx-auto">
                            <BtnVerProductos>
                                <Link to='/Productos' className='w-100'>
                                    Ver Productos
                                </Link>
                            </BtnVerProductos>
                        </div>
                    </div>
                </ContentItemCarrito>
            </div>
        </div>
    );
}

export default EmptyCarrito;