import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import styled from 'styled-components'




const Index = ({text}) => {

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    useEffect(() => {
        const timeout = setInterval(() => {
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, 4000);

        return () => clearInterval(timeout)
    }, [fadeProp])

    
    return (
        <>

            <ContentGralLoading>
                <ContentLoading>
                    <div className="Rueda_loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <br />
                    <ContentTituloLoading>
                        Cargando
                    </ContentTituloLoading>
                </ContentLoading>
            </ContentGralLoading>
        </>
    )
}

export default Index

const ContentGralLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh !important;
    width: 100%;
    background:linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, .9) 96.87%); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const ContentLoading = styled.div`
    width: 70%;
    margin: auto;
    text-align: center;
`

const ContentTituloLoading = styled.div`
    font-family: var(--Font-Roboto-Regular);
    font-weight: bold;
    font-style: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--Color-Negro);
    margin-top: -2%;
    letter-spacing: 2px;
`
Index.defaultProps = {
    text: ''
}

Index.propTypes = {
    text: PropTypes.string,
}