import axios from "axios";
import { EndPointApi, Credencials } from "./config";


// Obtiene todas las categorias
export const get_ValorDolar = async () => {
    try {

        const {data} = await axios({
            method:'POST',
            url: `${EndPointApi}/getCotizacionUSD`,   
            auth: Credencials
        })

        if(data){
            return data
        }

        return null

    } catch (error) {
        return null
    }
}