import React from 'react';
import { Link } from 'react-router-dom';

import { ContentCardUserPanelNormal, SpanPanelUser } from "./Estilos";

const CardsUserPanel = ({titulo, icono, link, texto, }) => {
    return (
        <ContentCardUserPanelNormal>
            <Link to={link}>
                <SpanPanelUser>
                    <h3>{titulo}</h3>
                    <i className={icono}></i>
                    <p>{texto}</p>
                </SpanPanelUser>
            </Link>

        </ContentCardUserPanelNormal>
    );
}



export default CardsUserPanel;