import React from 'react';

import LogoRuedaImg from "../../assets/Logos/logo.svg";


import {
    ContentCardPrincipal,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
} from "./Estilos";

import { BotonPrimarioFondoBlanco, BotonPrimarioFondoRojo } from "../Botones/Botones";

const UserPerfil = ({ perfil }) => {


    const options = { year: 'numeric', month: 'long', day: 'numeric' };


    return (
        <>
            <ContentCardPrincipal>
                <div className="row mb-2">
                    <div className="col-md-5">
                        <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                        <CapsulaEcommerce>
                            <p>E-Commerce</p>
                        </CapsulaEcommerce>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-md-12">
                        <BienvenidoText>
                            Hola {perfil.Cliente.Nombre}
                        </BienvenidoText>
                        <SubTituloBienvenido>
                            Estos son tus datos de perfil
                        </SubTituloBienvenido>
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Nombre
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio'
                            value={perfil.Cliente.Nombre}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Apellido
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Cliente.Apellido}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2 small_input">
                        <LabelForm>
                            Mail
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Cliente.CorreoElectronico}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Telefono
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Cliente.Telefono}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Fecha Nacimiento
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Cliente.FechaNacimiento }
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Usuario desde
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={new Date(perfil.Cliente.CreatedAt).toLocaleDateString("es-ES", options) }
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-12 mt-2 mb-2">
                        <LabelForm>
                            Razon Social
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Perfil.RazonSocial}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            CUIL
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Perfil.CUIL}
                            readOnly
                            disabled

                        />
                    </div>

                    <div className="col-md-6 mt-2 mb-2">
                        <LabelForm>
                            Condición frente al IVA
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Perfil.CondicionFrenteAlIVA.CondicionFrenteAlIva}
                            readOnly
                            disabled

                        />
                    </div>
                    <div className="col-md-12 mt-2 mb-2">
                        <LabelForm>
                            Domicilio
                        </LabelForm>

                        <input
                            type="text"
                            className='Rueda_InputInicio small_input'
                            value={perfil.Perfil.Domicilio}
                            readOnly
                            disabled

                        />
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-md -6 mt-2 mb-2">
                        <BotonPrimarioFondoRojo 
                            link='/UserPerfil'
                            texto='Editar Perfil'
                        />
                    </div>
                    <div className="col-md -6 mt-2 mb-2">
                        <BotonPrimarioFondoBlanco 
                            link='/UserPanel'
                            texto='Ir a mi panel'
                        />

                    </div>
                </div>

            </ContentCardPrincipal>
        </>

    )
}



export default UserPerfil;