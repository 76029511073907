import { useState, useEffect } from 'react'

import { search_ProductosBySearch } from '../services/Productos'


export default function useFetch({search}) {
    const [state, setState] = useState({
        isLoading: true,
        isSuccces: false,
        isFailed: false,
        data: null,
        error: false
    })

    useEffect(() => {
        const fetchData = async () => {

            try {
                const result = await search_ProductosBySearch(search)

                if (result) {
                    setState({
                        isLoading: false,
                        isSuccces: true,
                        isFailed: false,
                        error: false,
                        data: result
                    })
                }

                if (!result) {
                    setState({
                        isLoading: false,
                        isSuccces: true,
                        isFailed: false,
                        error: true,
                        data: null
                    })
                }

            } catch (error) {
                setState({
                    isLoading: false,
                    isSuccces: true,
                    isFailed: false,
                    error: true,
                    data: null
                })
            }
        }
        fetchData()
    }, [])

    return state

}