import React, {Suspense} from 'react';
import { Link } from "react-router-dom";

import { ContentCardCategoria, ImagenCategoria, SpanNombreCategoria } from "./Estilos";

import SinFoto from "../../assets/Images/sin_foto.png";

const CardsCategorias = ({categoria}) => {
    return (
        <Suspense>
        <div className="col-md-3 col-6 mt-2 mb-2 p-2">
           
            <ContentCardCategoria>
                <Link to={`/Productos/ByCategoria/${categoria.IdentificacionSicoi}`} className='w-100'>
                    {
                        categoria.URLImagen === '' ?
                        <>  
                            <ImagenCategoria src={SinFoto} alt={`Rueda Digital Categoria ${categoria.Nombre}`} />
                        </>
                        :
                        <>
                            <ImagenCategoria src={`https://api.ruedadigital.com.ar/${categoria.URLImagen}`}  alt={`Rueda Digital Categoria ${categoria.Nombre}`} />
                        </>
                    }

                    <SpanNombreCategoria>
                        {categoria.Nombre}
                    </SpanNombreCategoria>


                </Link>
                
            </ContentCardCategoria>
          
        </div>
        </Suspense>
    );
}



export default CardsCategorias;