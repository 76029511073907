import React, { useState, useContext } from 'react';
import Cookies from "universal-cookie";
import { Link } from 'react-router-dom';

import { user_Login } from "../../services/Users";

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import {
    ContentCardLoginForm,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
    ContentMsgError,
    ErrorValueInput,
    TenesCuenta,
    ContentOlvidePass
} from "./Estilos";

import UserContext from "../../context/userContext";

import { RedSubmitBoton, BotonPrimarioFondoBlanco } from "../Botones/Botones";
import Loading from "../Loading/Loading";


const cookies = new Cookies()

const LoginForm = () => {


    const [loading, setLoading] = useState(false)
    const [usuario, setUsuario] = useState('')
    const [errorUser, setErrorUser] = useState('')
    const [pass, setPass] = useState('')
    const [errorPass, setErrorPass] = useState('')
    const [errorLogin, setErrorLogin] = useState('')


    const context = useContext(UserContext)


    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = false
        setErrorUser('')
        setErrorPass('')
        if(usuario === ''){
            setErrorUser('Este campo no puede estar vacio.')
            errors = true
        }

        if(pass === ''){
            setErrorPass('Este campo no puede estar vacio')
            errors = true
        }

        if(errors) return

        setLoading(true)
        const result = await user_Login(usuario, pass)

        if(result.status === 'OK'){
            cookies.set("user_rueda", result.token, {path: "/"})
            cookies.set("name_rueda", result.name, {path: "/"})
            context.setJWT(result.token)
            window.location.href = "./UserPanel";
        }

        if(result.status === 'ERROR'){
            setErrorLogin(result.msg)
        }

        setLoading(false)

    }


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-6 col-12 mx-auto">
                    <ContentCardLoginForm>
                        <div className="row mb-2">
                            <div className="col-md-5">
                                <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                                <CapsulaEcommerce>
                                    <p>E-Commerce</p>
                                </CapsulaEcommerce>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <BienvenidoText>
                                    Bienvenido
                                </BienvenidoText>
                                <SubTituloBienvenido>
                                    Para ingresar a tu cuenta de Rueda Digital primero debes iniciar sesión.
                                </SubTituloBienvenido>
                            </div>
                        </div>
                        { errorLogin === '' ?
                            null
                            :
                            <div className="row">
                                <div className="col-md-8 mx-auto text-center">
                                    <ContentMsgError>
                                        <ErrorValueInput>
                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errorLogin}
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                </div>
                            </div>
                        }


                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12 mt-2 mb-4">
                                    <LabelForm>
                                        Usuario
                                    </LabelForm>

                                    <input
                                        type="text"
                                        name='usuario'
                                        id='usuario'
                                        className='Rueda_InputInicio'
                                        placeholder='Ingresá tu usuario'
                                        value={usuario}
                                        onChange={(e)=>setUsuario(e.target.value)}
                                    />
                                    { errorUser === '' ?
                                        null
                                        :
                                        <ContentMsgError>
                                            <ErrorValueInput>
                                                <i className='bi bi-exclamation-triangle p-1' ></i>  {errorUser}
                                            </ErrorValueInput>
                                        </ContentMsgError>
                                    }
      
                                </div>
                                <div className="col-md-12 mt-2 ">
                                    <LabelForm>
                                        Contraseña
                                    </LabelForm>

                                    <input
                                        type="password"
                                        name='pass'
                                        id='pass'
                                        className='Rueda_InputInicio'
                                        placeholder='Ingresá tu contraseña'
                                        value={pass}
                                        onChange={(e)=>setPass(e.target.value)}
                                    />
                                    { errorPass === '' ?
                                        null
                                        :
                                        <ContentMsgError>
                                        <ErrorValueInput>
                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errorPass}
                                        </ErrorValueInput>
                                    </ContentMsgError>

                                    }

                                    <ContentOlvidePass>
                                        <Link to='/RecuperarPassword'>
                                            Olvidaste tu contraseña
                                        </Link>
                                    </ContentOlvidePass>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <RedSubmitBoton texto='Ingresar' />
                                </div>
                            </div>
                        </form>
                        
                        <div className="row mt-3">
                            <div className="col-md-4 align-self-center mobile-none">
                                <hr />
                            </div>
                            <div className="col-md-4 text-center">
                                <TenesCuenta>
                                    No tenés cuenta
                                </TenesCuenta>
                            </div>
                            <div className="col-md-4 align-self-center mobile-none">
                                <hr />
                            </div>
                            <div className="col-md-3 mx-auto">
                                <BotonPrimarioFondoBlanco
                                    link='/Registrarme'
                                    texto='Registrate'
                                />
                            </div>
                        </div>


                    </ContentCardLoginForm>

                </div>
            </div>
        </>

    )
}


export default LoginForm;