import React, { useState, useContext} from 'react';
import UserContext from "../../context/userContext";
import { edita_CarritoUsuario } from '../../services/Users';



import SinFoto from "../../assets/Images/sin_foto.png";

import { generaUnPrecio } from "../../helpers/generaPrecios";

import { ContentItemCarrito, ImagenPrincipalProducto, TituloPrecio, TituloProducto, DeleteItemDelCarrito } from "./Estilos";

import Loading from "../Loading/Loading";
import { ModalProductoAgregado as Modal } from "../Modals/Modales";





const FormatTitulo = ({ titulo, cantidad }) => {
    return (
        <TituloProducto>
            {titulo}
            <span>{cantidad}</span>
            <small>Rollo/s</small>

        </TituloProducto>
    )
}

export const FormatNumero = ({ numero, tipo }) => {
    const splitNumero = numero.split(',')
    const centavos = splitNumero[1].split('')

    return (
        <>
            
            <TituloPrecio>
            <sub className='w-100'>Precio Iva Inc.</sub> <br />
                {tipo} <small className='small_ARS'>ARS $</small><span>{splitNumero[0] === undefined ? '0': splitNumero[0] }</span>,<sup>{parseInt(centavos[0]) ? parseInt(centavos[0]).toString() : '0'}{parseInt(centavos[1]) ? parseInt(centavos[1]).toString() : '0'}</sup>

            </TituloPrecio>
        </>
    )
}



const CardsCarrito = ({ unItem, valorDolar, carrito }) => {

    let context = useContext(UserContext)

    const valorPrecio = generaUnPrecio(0, unItem.Producto.Precio, valorDolar);
    const valorPrecioTotal = generaUnPrecio(unItem.Cantidad, unItem.Producto.Precio, valorDolar)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [titulo, setTitulo] = useState('')
    const [msg, setMsg] = useState('')
    


  

    const deleteItem = async() => {
        const confirmDelete = window.confirm(`Estas por eliminar ${unItem.Cantidad} rollo/s de ${unItem.Producto.Nombre} de tu carrito.`)

        if(confirmDelete){
            setLoading(true)
            const data ={Carrito:[]}
            for (const unProducto of carrito) {
               if(unProducto.Id !== unItem.Id){
                const temp = {
                    CodigoSicoi: unProducto.Producto.CodigoSicoi,
                    Cantidad: unProducto.Cantidad
                }
                data.Carrito.push(temp)
               }
            }

            const result = await edita_CarritoUsuario(context.jwt.token, data)
            if(result){
                setTitulo('Todo ha ido bien')
                setMsg(`Hemos eliminado ${unItem.Cantidad} rollo/s de ${unItem.Producto.Nombre} de tu carrito.`)
                setModal(true)
            }else{
                setTitulo('Ups ...')
                setMsg('Ah ocurrido un error al intentar procesar tu solicitud.')
                setModal(true)
            }


            context.setItems(0)
            setLoading(false)
    
        }

        return

    }

    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    <ContentItemCarrito>
                        <div className='row'>
                            <div className="col-md-3 col-10 mx-auto">
                                {
                                    unItem.Producto.Imagenes.length === 0 ?
                                        <>
                                            <ImagenPrincipalProducto src={SinFoto} alt='Producto sin imagen' />
                                        </>
                                        :
                                        <>
                                            <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${unItem.Producto.Imagenes[0].URLImagen}`} alt={`Rueda Digital Producto ${unItem.Producto.Nombre}`} />
                                        </>
                                }
                            </div>
                            <div className="col-md-6 align-self-center">
                                {unItem.Producto.Nombre ? <FormatTitulo titulo={unItem.Producto.Nombre} cantidad={unItem.Cantidad} /> : null}
                                {valorPrecioTotal ? <FormatNumero numero={valorPrecio} tipo='Precio Unitario:' /> : null}
                                {valorPrecioTotal ? <FormatNumero numero={valorPrecioTotal} tipo='Costo:' /> : null}

                            </div>
                            <div className="col-md-3 col-10 mx-auto align-self-center">
                                <DeleteItemDelCarrito onClick={()=>deleteItem()}>
                                    <i className="bi bi-trash"></i>
                                    <span>Eliminar item</span>

                                </DeleteItemDelCarrito>
                            </div>
                        </div>
                    </ContentItemCarrito>

                    <Modal 
                        data={modal}
                        titulo={titulo}
                        message={msg}
                        error={true}
                        link=''
                        texto=''
                    
                    />

                </>

            }




        </>
    )
}



export default CardsCarrito;