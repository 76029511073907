import axios from "axios";
import { EndPointApi, Credencials } from "./config";

// Obtiene todas las categorias
export const getAll_Categorias = async () => {
    try {
        const {data} = await axios({
            method:'GET',
            url: `${EndPointApi}/categorias/getAll`,
            auth: Credencials
            
        })

        if(data.status === 'OK'){
            const result = data.data.filter(unDato => unDato.CantidadDeProductos !== 0)
            return {
                data: result
            }
        }


        return null
    } catch (error) {
        return null
    }
}