import React, {useState, useEffect, useContext} from 'react';
import UserContext from "../context/userContext";
import { Link } from 'react-router-dom';
import { get_DataInicio } from "../services/Inicio";


import Loading from "../components/Loading/Loading";

import {TituloBannerInicio, TituloSeccion  } from "../components/Titulos/Titulos";

import { ContentLinkVerTodas, ContentProductosInicio, ContentHome } from "../components/Inicio/Estilos";

import CardsCategoriaInicio from "../components/CardsCategorias/CardsCategoriasInicio";
import BarraBusqueda from '../components/Busqueda/Busqueda'
import CarrouselImagenes from "../components/Inicio/BannersImagen";
import CardsProducto from "../components/CardsProductos/CardsProductos";

import CarrouselTexto from "../components/Inicio/BannersTexto";


const Inicio = () => {
    const context = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [dataInicio, setDataInicio] = useState([])


    
    useEffect(()=>{
        const getData = async()=> {
            setLoading(true)

            if(context.inicio){
                setDataInicio(context.inicio) 
            }else{
                const data = await get_DataInicio()
                context.setDataInicio(data)
                setDataInicio(data) 
            }
            setLoading(false)

        }

        getData()
    },[])

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <ContentHome>
            <TituloBannerInicio titulo='Bienvenido a Rueda Digital' subtitulo='Una nueva forma de comprar con la calidad de siempre.' />

            <BarraBusqueda />
            
            <div className="row mt-4 mb-4">
                <div className="col-md-8 mx-auto">
                    <div className="row">
                        <div className="col-md-12 col-1"></div>
                        <div className="col-md-8 col-7">
                            <TituloSeccion  texto='Categorias'/>
                        </div>
                        <div className="col-md-4 col-3 align-self-center">
                            <ContentLinkVerTodas>
                                <Link to='/Categorias'>
                                    Ver todas
                                </Link>
                            </ContentLinkVerTodas>
                        </div>
                        <div className="col-md-12">
                            <div className="row p-3">
                                {   dataInicio.Categorias ?

                                    dataInicio.Categorias.map(unaCategoria => (
                                        <CardsCategoriaInicio categoria={unaCategoria} key={unaCategoria.Id} />
                                    ))
                                    :
                                    <>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row mt-4 mb-4">
                <div className="row">
                    <div className="col-md-10 col-12 mx-auto">
                        <CarrouselImagenes banners={dataInicio.BannersImagen} />
                    </div>
                </div>

            </div>

            <div className="row mt-4 mb-4">
                <div className="col-md-10 col-12 mx-auto">
                    <ContentProductosInicio>
                        { dataInicio.Productos && dataInicio.precioUsd ?
                            
                            <>
                            <div className="row mt-4 mb-4">
                                <div className="col-md-8 col-8 align-self-center">
                                    <TituloSeccion  texto={`Productos ${dataInicio.Productos.nameCategoriaUno}`}/>
                                </div>
                                <div className="col-md-4 col-4 align-self-center">
                                    <ContentLinkVerTodas>
                                        <Link to='/Categorias'>
                                            Ver todas
                                        </Link>
                                    </ContentLinkVerTodas>
                                </div>
                                {
                                    dataInicio.Productos.productosCategoriaUno.map(unProducto => (
                                        <CardsProducto producto={unProducto} key={unProducto.Id} precioDolar={dataInicio.precioUsd} />
                                    ))
                                }
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div className="row  mt-4 mb-4">
                                <div className="col-md-8 col-8 align-self-center">
                                    <TituloSeccion  texto={`Productos ${dataInicio.Productos.nameCategoriaDos}`}/>
                                </div>
                                <div className="col-md-4 col-4 align-self-center">
                                    <ContentLinkVerTodas>
                                        <Link to='/Categorias'>
                                            Ver todas
                                        </Link>
                                    </ContentLinkVerTodas>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    dataInicio.Productos.productosCategoriaDos.map(unProducto => (
                                        <CardsProducto producto={unProducto} key={unProducto.Id} precioDolar={dataInicio.precioUsd} />
                                    ))
                                }
                            </div>

                            </>
                            :
                            <>
                            </>

                        }
                    </ContentProductosInicio>

                </div>
            </div>

            <CarrouselTexto banners={dataInicio.BannersTexto} />
        </ContentHome>
    ) 
}



export default Inicio;