import styled from 'styled-components';


export const ContentButton = styled.div `
    width: 100%;
`

export const RedPrimaryButtonStyles = styled.button`
    background: var(--Color-Red-Primary);
    color: var(--Color-Blanco);
    font-family: var(--Font-DMSans-Regular);
    padding: 10px 15px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--Color-Red-Primary);
    transition: all .25s linear;
    &:hover{
        background: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
    }

    @media (max-width: 480px) {
        font-size: 12px;

    }

`

export const WhitePrimaryButtonStyles = styled.button`
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    font-family: var(--Font-DMSans-Regular);
    padding: 10px 15px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--Color-Red-Primary);
    transition: all .25s linear;

    &>i{
        margin-right: 10px;
    }
    &:hover{
        background: var(--Color-Red-Primary);
        color: var(--Color-Blanco);
    }

`