import styled from 'styled-components';


export const ContentCardItemCarrito = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 50px;

`

export const ContentItemCarrito = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 25px 50px;
    margin-top: 2%;
    margin-bottom: 2%;

    @media (max-width: 480px) {
        width: 90%;
        margin-left: 5%;

    }

`

export const ImagenPrincipalProducto = styled.img`
    width:70%;
    margin-left: 15%;
    border-radius: 10px;
`

export const TituloProducto = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    margin-bottom: 2%;
    color: var(--Color-Red-Primary);

    &>span{
        margin-left: 5px;
        margin-right: 5px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
        text-align: center;
        margin-top: 2%;
    }


`

export const TituloPrecio = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;

    &>span{
        font-family: var(--Font-DMSans-Bold);
        margin-left: 5px;
    }

    &>sup{
        font-family: var(--Font-DMSans-Bold);
    }

    @media (max-width: 480px) {
        font-size: 12px;
        text-align: left;
        margin-bottom: 2%;
        width: 110%;

    }

`

export const DeleteItemDelCarrito = styled.button`
    font-family: var(--Font-DMSans-Regular);
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: var(--Color-Red-Primary);
    border: none;
    border-radius: 15px;
    color: var(--Color-Blanco);
    transition: all .25s ease-in;

    &:hover{
        background-color: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
        &>a{
            background-color: none;
            color: var(--Color-Red-Primary);
        }
        
    }

    &>a{
        text-decoration: none;
        color: white;
    }

    &>a:hover{
        background-color: none;
        color: var(--Color-Red-Primary);
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }


`

export const TituloTotalCarrito = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 18px;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`
export const PrecioTotalCarrito = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 18px;
    color: var(--Color-Red-Primary);

    @media (max-width: 480px) {
        font-size: 14px;
    }
`

export const ConfirmCarritoCarrito = styled.button`
    font-family: var(--Font-DMSans-Regular);
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: var(--Color-Green-Aproved);
    border: none;
    border-radius: 15px;
    color: var(--Color-Blanco);
    transition: all .25s ease-in;

    &:hover{
        background-color: var(--Color-Blanco);
        color: var(--Color-Green-Aproved);
        
    }

    @media (max-width: 480px) {
        font-size: 12px;

    }




`