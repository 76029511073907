import styled from 'styled-components';



export const ContentCardProductos = styled.div`
    padding: 20px;
    background-color: var(--Color-Blanco);
    border-radius: 15px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    text-align: center;
    transition: all .25s linear;

    &>a{

        text-decoration: none;
        font-family: var(--Font-DMSans-Bold);
        font-size: 14px;
        color: var(--Color-Negro);
        transition: all .25s ease-in;
    }

    &:hover{
        transform: scale(0.9);

        & >a{
            color: var(--Color-Red-Primary);
            font-family: var(--Font-DMSans-Bold);
        }

    }

    @media (max-width: 480px) {
        padding: 10px;
        width: 100%;
    }


`

export const ImagenProducto = styled.img`
    width: 100%;
    margin-bottom: 3%;
    min-height: 200px;
    max-height: 250px;

`

export const ContentInfoCategoria = styled.p`
    width: 100%;
    text-align: left;

    & > span {
        color: var(--Color-Grey-Dark);
        font-family: var(--Font-DMSans-Regular);
        font-size: 12px;
    }

    @media (max-width: 480px) {
        & > span {
        font-size: 10px;
        }
    }
`

export const ContentNombreProducto = styled.div`
    width: 100%;
    text-align: left;
    overflow: hidden;
    height: 50px;
    margin-top: 2%;
    margin-bottom: 1%;

    & > p {
        color: var(--Color-Negro);
        font-family: var(--Font-DMSans-Regular);
        font-size: 14px;
        overflow:hidden;
        position:relative;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        & > p {
        color: var(--Color-Negro);
        font-family: var(--Font-DMSans-Regular);
        font-size: 12px;
        overflow:hidden;
        position:relative;
        margin-bottom: 0;
        }
    }
`

export const ContentPrecioProducto = styled.p`
    color: var(--Color-Negro);
    font-family: var(--Font-DMSans-Bold);
    font-size: 14px;
    text-align: left;
    margin-bottom: 0;

    & > span {
        font-family: var(--Font-DMSans-Regular);
        font-size: 80%;
        padding-right: 5px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        & > span {
            font-size: 10px;
        }
    }

`

export const IconoVerMas = styled.i`
     color: var(--Color-Red-Primary);
    font-size: 24px;

    &:hover{
        color: var(--Color-Negro);
       
    }

`


export const ContentCardUnProducto = styled.div`
    padding: 50px;
    background-color: var(--Color-Blanco);
    border-radius: 15px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);

`

export const ImagenPrincipalProducto = styled.img`
    width:100%;
    border-radius: 10px;

`

export const ContentLinkCategoria = styled.div`
    width: 100%;
    text-align: left;
    color:  var(--Color-Grey-Dark);
    margin-bottom: 5px;

    & > a {
        text-decoration: none;
        color: var(--Color-Grey-Dark);
        transition: all .25s ease-in;
    }

    & > a > i {
        color: var(--Color-Negro);
        font-size: 12px;
        padding-right: 5px;
        transition: all .25s ease-in;
    }

    & > a > span {
        font-family: var(--Font-DMSans-Regular);
        font-size: 12px;
        
    }

    &:hover{
        & > a{
            color: var(--Color-Red-Primary)
        }

        & > a > i{
            color: var(--Color-Red-Primary)
        }
    }
`

export const ContentNombreUnProducto = styled.div`
    width: 100%;
    margin-bottom: 10px;

    & > p {
        text-align: left;
        color: var(--Color-Negro);
        font-family: var(--Font-DMSans-Bold);
        font-size: 18px;
        margin-bottom: 0;
    }
`

export const TextoDataUnProducto = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Negro);
    margin-bottom: 0;

    @media (max-width: 480px) {
        font-size: 12px;

    }

    
`

export const ContentPrecioUnProducto = styled.div`
    color: var(--Color-Negro);
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    text-align: right;
    margin-bottom: 0;

    & > span {
        font-family: var(--Font-DMSans-Regular);
        font-size: 14px;
        padding-right: 5px;
    }

    @media (max-width: 480px) {
        font-size: 14px;

        &>span{
            font-size: 12px;
        }
    }

`
export const ContentMessageSinDescripcion = styled.div `
    padding: 25px;
    text-align: center;
    background-color: var(--Color-Grey-Light);

    & > p {
        color: var(--Color-Grey-Dark);
        margin-bottom: 0;
        font-size: 12px;
    }

`

export const ContentProductosRelacionados = styled.div`
    padding: 50px;
    background-color: var(--Color-Grey-Light);
    border-radius: 25px;
    margin-top: 3%;

    @media (max-width: 480px) {
        padding: 15px 10px;
        width: 105%;
        margin: 1%;
    }
`
export const TitulosProductosRelacionados = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 20px;
    color: var(--Color-Negro);
    margin-bottom: 0;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`

export const ContentLinkRelacionados = styled.div`
    width: 100%;
    text-align: right;
    color:  var(--Color-Grey-Dark);
    margin-bottom: 5px;

    & > a {
        text-decoration: none;
        color: var(--Color-Red-Primary);
        transition: all .25s ease-in;
    }


    &:hover{
        & > a{
            color: var(--Color-Grey-Dark)
        }

    }

    @media (max-width: 480px) {
        & > a {
            font-size: 12px;
        }
       
    }
`

export const ProductoSinStock = styled.p`
    color: var(--Color-Red-Primary);
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
`

export const TitulosDescripcion = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    margin-bottom: 0;

    @media (max-width: 480px) {
        font-size: 12px;
       
    }

    
`

export const TextoDescripcion = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 14px;
    margin-bottom: 0;

    @media (max-width: 480px) {
        font-size: 12px;
       
    }
`
