import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { get_Token } from "../helpers/getToken";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import CambiarPasswordForm from "../components/LoginForm/CambiarPassForm";

const CambiarPassword = () => {

    const {mail, hash} = useParams()

    useEffect(()=>{
        const data = () =>{
            const token = get_Token()
            if(token){
                window.location.href='/UserPanel'
            }
        }
        data()
    },[])


    return(
        <>
            <Helmet>
                <title>Rueda Digital - Recuperar contraseña</title>
            </Helmet>
            
            <TituloPrincipal texto='Reestablece tu contraseña' />

            <CambiarPasswordForm mail={mail} hash={hash} />


        </>
    );
}



export default CambiarPassword;