import React, {Suspense, useState, useEffect} from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


import { get_StatusApi } from "./services/StatusApi";

import Navegacion from "./components/Navegacion/Navegacion";
import Footer from "./components/Footer/Footer";


import IndexPage from "./pages/Inicio";

import Busqueda from './pages/Busquedas'

import Categorias from "./pages/Categorias";
import CategoriasProductos from "./pages/ProductosXCategoria";

import Productos from "./pages/Productos";
import VerUnProducto from "./pages/UnProducto";

import Contacto from "./pages/Contacto";

import Ayuda from "./pages/Ayuda";

import Registro from "./pages/Registro";
import ValidRegistro from "./pages/ValidMail";

import RecuperaPassword from "./pages/RecuperaPassword";
import CambiarPassword from "./pages/CambiarPass";

import Ingresar from "./pages/Ingresar";


import UserPanel from "./pages/UserPanel";
import UserPerfil from "./pages/UserPerfil";
import UserVerPerfil from "./pages/UserVerPerfil";

import UserCarrito from "./pages/UserCarrito";
import UserOrdenes from "./pages/UserOrdenes";
import UserOrden from "./pages/UserVerOrden";
import { ConfirmPagos, ErrorPago, PendingPago  } from "./pages/Pagos";

import ErrorNotFound from "./pages/NotFound";
import Loading from "./components/Loading/Loading";
import EnMantenimiento from "./pages/EnMantenimiento";

import { UserContextProvider } from "./context/userContext";

import './App.css';

const App = () =>{

  const [statusApi, setStatusApi] = useState(0)
 
  useEffect(()=>{
    const getStatus = async () => {
      const result = await get_StatusApi()
      if(result){
        setStatusApi(2)
      }else{
        setStatusApi(1)
      }
    }
    getStatus()
  },[setStatusApi])

  

  if(statusApi === 0){
    return (
      <Loading />

    )
  }

  if(statusApi === 1){
    return (
      <EnMantenimiento />
    )
  }

  return (
    <UserContextProvider>
      <Suspense fallback={<h1>Cargando</h1>}>
        <Router>
          <Navegacion />
          <Routes>
        
            <Route path='/' element={<IndexPage />} />

            <Route path='/Categorias' element={<Categorias/>} />
            <Route path='/Productos/ByCategoria/:sicoi' element={<CategoriasProductos/>} />

            <Route path='/Busquedas' element={<Busqueda />} />

            <Route path='/Productos' element={<Productos/>} />
            <Route path='/Producto/:sicoi' element={<VerUnProducto />} />

            <Route path='/Contacto' element={<Contacto/>} />

            <Route path='/Ayuda' element={<Ayuda/>} />

            <Route path='/Registrarme' element={<Registro />}  />
            <Route path='/confirmRegistro/:mail/:userHash' element={<ValidRegistro />}  />

            <Route path='/RecuperarPassword' element={<RecuperaPassword />} />
            <Route path='/cambiaPass/:mail/:hash' element={<CambiarPassword />} />

            <Route path='/Ingresar' element={<Ingresar/>} />

            <Route path='/UserPanel' element={<UserPanel />} />
            <Route path='/UserPerfil' element={<UserPerfil />} />
            <Route path='/UserAllPerfil' element={<UserVerPerfil />} />

            <Route path='/UserCarrito' element={<UserCarrito />} />
            <Route path='/UserOrdenes' element={<UserOrdenes />} />
            <Route path='/UserOrden/:hash' element={<UserOrden />} />    

            <Route path='/success/:hash' element={<ConfirmPagos />} />
            <Route path='/failure/:hash' element={<ErrorPago/>} />
            <Route path='/pending/:hash' element={<PendingPago/>} />
            

            <Route path={'*'} element={<ErrorNotFound />} />

          

          </Routes>

          <Footer />
        </Router>

    </Suspense>
    </UserContextProvider>

  )
}




export default App;
