import React, {useState, useEffect, useContext} from 'react';
import UserContext from "../context/userContext";
import { get_Carrito } from "../services/Users";

import { get_ValorDolar } from "../services/CotizacionDolar";


import {TituloPrincipal  } from "../components/Titulos/Titulos";
import Loading from "../components/Loading/Loading";



import UnItemEnCarrito from "../components/CardsCarrito/CardsCarrito";
import CardsTotalCarrito from "../components/CardsCarrito/CardsTotalCarrito";
import EmptyCarrito from "../components/CardsCarrito/EmptyCarrito";


const Carrito = () => {
    const context = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [precioUsd, setPrecioUsd] = useState()
    const [carrito, setCarrito] = useState()



    useEffect(() => {
        const getData = async() => {
            setLoading(true)
            if(!context.jwt){
                window.location.href='/Ingresar'
            }

            const result = await get_Carrito(context.jwt.token)

            if(result){
                setCarrito(result.carrito)
            }

            const dolar = await get_ValorDolar()
            if(dolar){
                setPrecioUsd(dolar)

            }
            setLoading(false)
        }

        getData()
    },[])



    return (
        <>
            { loading ?
                <Loading />
                :
                <>
                    <TituloPrincipal texto='Mi carrito'  />

                    {
                        carrito ?
                        <>
                            { carrito.length === 0 ?
                                <>
                                    <EmptyCarrito />
                                </>
                                :
                                <> 
                                    {   precioUsd ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-6 mx-auto mt-4 ">
                                                        {
                                                            carrito.map(unItem => (
                                                                <UnItemEnCarrito unItem={unItem} key={unItem.Id} valorDolar={precioUsd} carrito={carrito} />
                                                            ))
                                                        }

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 mx-auto">
                                                    <CardsTotalCarrito productos={carrito} precioUds={precioUsd} token={context.jwt.token} />
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <Loading />

                                    }
        

                                </>
                                
                            }

                        </>
                        :
                        <>
                            <Loading />
                        </>
                    }
                </>    

            }        
        
        
        </>
    );
}


export default Carrito;