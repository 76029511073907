import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

import { generaUnPrecioBase } from "../../helpers/generaPrecios";

import LogoRuedaImg from "../../assets/Logos/logo.jpg";


const styles = StyleSheet.create({


  image: {
    marginVertical: 5,
    marginHorizontal: 175,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 16,
    color: '#000'

  },
  ordenId: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000'
  },
  titleItem: {
    fontSize: 10,
    marginTop: 5,
    color: '#000'
  },
  dataItem: {
    fontSize: 10,
    marginTop: 5,
    color: '#000'
  },
  line: {
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  titleDetalle: {
    textDecoration: 'underline',
    marginTop: 20,
    marginBottom: 10,
    fontSize: 10,
  },
  containerDetalle:{
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10
  },
  section: {
    flexGrow: 1,
    fontSize: 10,
    textAlign: 'left'
  }


});



const DocuPdf = ({ orden }) => {

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <Document>
      <Page
        size="A4"
        style={{
          backgroundColor: "white",
        }}
      >
        <View
          style={{
            padding: 50,
          }}
        >

          <Image
            src={LogoRuedaImg}
            style={styles.image}
          />


          <Text style={styles.title}>
            Orden Id:  <Text style={styles.ordenId}>{orden.Orden.Id.toString().padStart(4, "0000")}</Text>
          </Text>

          <Text style={styles.titleItem}>
            Total:  <Text style={styles.dataItem}>ARS $ {orden.Orden.TotalOrden}</Text>
          </Text>

          <Text style={styles.titleItem}>
            Fecha:  <Text style={styles.dataItem}>{new Date(orden.Orden.CreatedAt).toLocaleDateString("es-ES", options)}</Text>
          </Text>

          <Text style={styles.titleItem}>
            Estado:  <Text style={styles.dataItem}>{orden.Orden.EstadoPago}</Text>
          </Text>

          <Text style={styles.titleDetalle}>
            Detalles de la Orden
          </Text>



          {
            orden.DetallesOrden.map(unDetalle => (
              <View key={unDetalle.Id} style={styles.containerDetalle}>
                    <Text style={styles.section}>
                      {unDetalle.Producto.Nombre}
                    </Text>

                    <Text style={styles.section}>
                       {unDetalle.Cantidad} rollo/s
                    </Text>

                    <Text style={styles.section}>
                      ref: {unDetalle.Producto.CodigoSicoi} 
                    </Text>

                    <Text style={styles.section}>
                      ARS $ {generaUnPrecioBase(0, unDetalle.PrecioUnitario, unDetalle.TipoCambio)} x rollo
                    </Text>
              
              </View>
            ))
          }
        </View>





      </Page>
    </Document>
  )
}

export default DocuPdf