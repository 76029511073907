import styled from 'styled-components';

export const ContentTituloPrincipal = styled.div`
    width: 100vw;
    background-color: var(--Color-Red-Primary);
    padding: 50px;
`
export const TituloBannerPrincipalStyles =styled.p`
    color: var(--Color-Blanco);
    font-family: var(--Font-Roboto-Black);
    font-size: 2.2rem;
    margin-bottom: 0;

    @media (max-width: 480px) {
        font-size: 1.25rem;
    }

`

export const SubTituloBannerPrincipalStyles =styled.p`
    color: var(--Color-Blanco);
    font-family: var(--Font-Roboto-Regular);
    font-size: 1.5rem;
    margin-bottom: 0;
    @media (max-width: 480px) {
        font-size: 1rem;
    }
`

export const TituloPrincipalStyles =styled.p`
    color: var(--Color-Blanco);
    font-family: var(--Font-Roboto-Black);
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 480px) {
        font-size: 16px;
    }

`

export const ContentTituloSeccion = styled.div `
    width: 100%;

    & > p {
        color: var(--Color-Negro);
        font-family: var(--Font-Roboto-Black);
        margin-bottom: 0;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        & > p {
            font-size: 14px;
            margin-bottom: 0;        
        }
    }
`

