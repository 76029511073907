import React, {useState, useEffect, useContext, Suspense} from 'react';
import UserContext from "../context/userContext";
import { get_PerfilUsuario } from "../services/Users";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import Loading from "../components/Loading/Loading";

import CardsPanel from "../components/UserPanel/CardsInicio";
import CardsUserPanel from "../components/UserPanel/CardsUserPanel";

const UserPanel = () => {

    const context = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [perfil, setPerfil] = useState(null)

    useEffect(()=> {
        const data = async () =>{
            setLoading(true)
            if(!context.jwt){
                window.location.href='/Ingresar'
            }else{
                const result = await get_PerfilUsuario(context.jwt.token)
                if(result.status === 'OK'){
                    setPerfil(result.data.Perfil)
                }else{
                    setPerfil(null)
                }

            }

            setLoading(false)
            
        }
        data()
       
        
    },[])


    if(loading){
        return (
            <Loading />
        )
    }
  
    return (
        <>
            <Suspense fallback={<Loading/>}>

                <TituloPrincipal texto={`¡Hola ${context.jwt.name}!`} />

                <div className="row mt-4 mb-4">
                    <div className="col-md-8 mx-auto">


                    { perfil === null ?
                            <div className="row">
                                <div className="col-md-8 col-8 mb-2 mx-auto">
                                    <CardsPanel 
                                        invalid={true}
                                        tipo='Carga tus datos de perfil'
                                        msg='Al parecer todavia no tienes cargados los datos de tu perfil.'
                                        submsg='Esta información es necesaria para poder realizar tus pedidos de forma correcta.'
                                        link='/UserPerfil'
                                        texto='Cargar mi perfil'
                                    >

                                    </CardsPanel>

                                </div>
                            </div>

                            :
                            <>
                            {
                                perfil.NSicoi === '' ?
                                <div className="row">
                                    <div className="col-md-8 col-11 mx-auto">
                                        <CardsPanel 
                                            invalid={true}
                                            tipo='Tus datos de perfil estan siendo revisados.'
                                            msg=''
                                            submsg='Esta chequeando tus datos de perfil para que al momento de utilizar la plataforma no tengas ningun inconveniente.'
                                            link='/'
                                            texto='Ir al inicio'
                                        >

                                        </CardsPanel>

                                    </div>
                                </div>
                                :    
                                <div className="row">
                                    <div className="col-md-4 col-10 mx-auto mb-2">
                                        <CardsUserPanel 
                                            titulo='Mi Perfil'
                                            link='/UserAllPerfil'
                                            icono='bi bi-person-bounding-box'
                                            texto='Revisa y actualiza tus datos de perfil.'
                                        />
                                    </div>
                                    <div className="col-md-4 col-10 mx-auto mb-2">
                                        <CardsUserPanel 
                                            titulo='Mi Carrito'
                                            link='/UserCarrito'
                                            icono='bi bi-cart-fill'
                                            texto='Agrega o elimina articulos de tu carrito.'
                                        />
                                    </div>
                                    <div className="col-md-4 col-10 mx-auto mb-2">
                                        <CardsUserPanel 
                                            titulo='Mis Ordenes'
                                            link='/UserOrdenes'
                                            icono='bi bi-card-checklist'
                                            texto='Revisa tu historial de ordenes.'
                                        />
                                    </div>
                                </div>
                            }
                            
                            </>


                        }
       
                    </div>
                </div>


            </Suspense>
            

        </>

    )
    
        


}



export default UserPanel;