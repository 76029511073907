import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { user_Register } from "../../services/Users";


import LogoRuedaImg from "../../assets/Logos/logo.svg";

import {
    ContentCardLoginForm,
    ImgLogoRueda,
    CapsulaEcommerce,
    BienvenidoText,
    SubTituloBienvenido,
    LabelForm,
    ContentMsgError,
    ErrorValueInput,
    TenesCuenta
} from "./Estilos";


import { RedSubmitBoton, BotonPrimarioFondoBlanco } from "../Botones/Botones";
import Loading from "../Loading/Loading";
import { ModalRegistroUsuario as ModalExito, ModalErrorContacto as ModalError } from "../Modals/Modales";

const RegisterForm = () => {
    const [type, setType] = useState('text')

    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)



    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-6 col-12 mx-auto">
                    <ContentCardLoginForm>
                        <div className="row mb-2">
                            <div className="col-md-5">
                                <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                                <CapsulaEcommerce>
                                    <p>E-Commerce</p>
                                </CapsulaEcommerce>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <BienvenidoText>
                                    Bienvenido
                                </BienvenidoText>
                                <SubTituloBienvenido>
                                    Para registrarte debes completar los siguientes datos.
                                </SubTituloBienvenido>
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                nombre: '',
                                apellido: '',
                                fechaNac: '',
                                tel: '',
                                usuario: '',
                                pass: '',
                                repitpass:''
                            }}

                            validate={(valores) => {

                                
                                const expresionLetras = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
                                const expresionMail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

                                const msgCampoObligatorio = 'Esta campo es obligatorio.'
                                const msgSoloContieneLetras = 'Este campo solo puede contener letras.'
                                let errores = {}

                                if (!valores.nombre) {
                                    errores.nombre = msgCampoObligatorio
                                } else if(!expresionLetras.test(valores.nombre)){
                                    errores.nombre = msgSoloContieneLetras
                                }

                                if (!valores.apellido) {
                                    errores.apellido = msgCampoObligatorio
                                } else if(!expresionLetras.test(valores.apellido)){
                                    errores.apellido = msgSoloContieneLetras
                                }

                                if (!valores.fechaNac) {
                                    errores.fechaNac = msgCampoObligatorio
                                }else if(valores.fechaNac){
                                    const tiempoPermitido = 18 * 365;
                                    const fecha = new Date(valores.fechaNac)
                                    const hoy = new Date()
                                    const resta = hoy.getTime() - fecha.getTime();
                                    const tiempoTranscurrido = Math.round(resta / (100 * 60 * 60 * 24))
    
                                    if (tiempoTranscurrido < tiempoPermitido){
                                        errores.fechaNac = 'Debes ser mayor de 18 años para registrarte.'
                                    }
                                }

                                if (!valores.tel) {
                                    errores.tel = msgCampoObligatorio
                                }

                                if (!valores.usuario) {
                                    errores.usuario = msgCampoObligatorio
                                } else if(!expresionMail.test(valores.usuario)){
                                    errores.usuario = 'Correo invalido.'
                                }

                                if (!valores.pass) {
                                    errores.pass = msgCampoObligatorio
                                }else if(valores.pass.length < 6){
                                    errores.pass = 'La contraseña debe contener al menos 6 caracteres.'
                                }else if(valores.repitpass !== valores.pass){
                                    errores.repitpass = 'Las contraseñas no coinciden.'
                                }

                                if (!valores.repitpass) {
                                    errores.repitpass = msgCampoObligatorio
                                } else if(valores.repitpass !== valores.pass){
                                    errores.repitpass = 'Las contraseñas no coinciden.'
                                }

                                return errores
                            }}

                            onSubmit={async (valores) => {
                                setLoading(true)
                                const body = {
                                    Nombre: valores.nombre ,
                                    Apellido: valores.apellido,
                                    FechaNacimiento: valores.fechaNac,
                                    Telefono:  valores.tel,
                                    CorreoElectronico: valores.usuario,
                                    Password: valores.pass
                                }

                                const result = await user_Register(body)

                                if(result.status === 'OK'){
                                    setModalExito(true)
                                }else{
                                    setMsg(`${result.msg}`)
                                    setModalError(true)
                                }

                                setLoading(false)
                            }}

                        >
                            {
                                ({ errors }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='nombre'>
                                                    Nombre
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='nombre'
                                                    id='nombre'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu nombre.'
                                                />

                                                <ErrorMessage name='nombre' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>  {errors.nombre}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='apellido'>
                                                    Apellido
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='apellido'
                                                    id='apellido'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu apellido'
                                                />

                                                <ErrorMessage name='apellido' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.apellido}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='fechaNac'>
                                                    Fecha Nacimiento
                                                </LabelForm>

                                                <Field
                                                    type={type}
                                                    onFocus={()=>setType('date')}
                                                    name='fechaNac'
                                                    id='fechaNac'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu fecha de Nacimiento'
                                                />

                                                <ErrorMessage name='fechaNac' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.fechaNac}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>
                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='telefono'>
                                                    Telefono
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='tel'
                                                    id='tel'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu numero de contacto.'
                                                />

                                                <ErrorMessage name='tel' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.tel}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                            <div className="col-md-12 mt-2 mb-4">
                                                <LabelForm htmlFor='usuario'>
                                                    Correo Electronico (usuario)
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='usuario'
                                                    id='usuario'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu numero de contacto.'
                                                />

                                                <ErrorMessage name='usuario' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.usuario}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />

                                            </div>

                                            
                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='pass'>
                                                    Contraseña
                                                </LabelForm>

                                                <Field
                                                    type='password'
                                                    name='pass'
                                                    id='pass'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu contraseña.'
                                                />

                                                <ErrorMessage name='pass' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.pass}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>
                                            <div className="col-md-6 mt-2 mb-4">
                                                <LabelForm htmlFor='repitpass'>
                                                    Repeti tu contraseña
                                                </LabelForm>

                                                <Field
                                                    type="password"
                                                    name='repitpass'
                                                    id='repitpass'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Repeti tu contraseña.'
                                                />

                                                <ErrorMessage name='repitpass' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput>
                                                            <i className='bi bi-exclamation-triangle p-1' ></i>    {errors.repitpass}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-md-3">
                                                <RedSubmitBoton texto='Registrarme' />
                                            </div>
                                        </div>



                                    </Form>
                                )
                            }



                        </Formik>
                        
                        <div className="row mt-3">
                            <div className="col-md-5 align-self-center mobile-none">
                                <hr />
                            </div>
                            <div className="col-md-2 col-12">
                                <TenesCuenta>
                                    Tenés cuenta
                                </TenesCuenta>
                                
                            </div>
                            <div className="col-md-5 align-self-center mobile-none">
                                <hr />
                            </div>
                            <div className="col-md-3 mx-auto">
                                <BotonPrimarioFondoBlanco 
                                    link='/Ingresar'
                                    texto='Ingresa'
                                />
                            </div>
                        </div>
                    </ContentCardLoginForm>

                </div>
            </div>

            <ModalExito 
                data={modalExito}
            />

            <ModalError
                data={modalError}
                icono='bi bi-person-x-fill'
                titulo='Ups...'
                message={msg}
            />
        </>

    )
}


export default RegisterForm;