import React from 'react';

import { TituloResultado } from './Estilos'

const TituloResultadoBusqueda = ({title}) => {
    return (
        <TituloResultado>
            <p>Hemos encontrado los siguientes resultados  <br /> para tu búsqueda <span>"{title}"</span>. </p>
        </TituloResultado>
    )
}

export default TituloResultadoBusqueda