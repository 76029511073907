export const EndPointApi = 'https://api.ruedadigital.com.ar/api/v1'

export const Credencials = {
    username: 'APIDDDev',
    password: 'APIDDDev!'
}


export const EndPointGetDolarDJS = 'https://api.digitaljs.com.ar/api/v1/getCotizacionDolar'

export const CredencialDJS = {
    username: 'APIDJSDDev',
    password: 'APIDJSDev!'
}