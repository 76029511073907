import React from "react";
import 'bootstrap'

import {ContentTextoBanner  } from "./Estilos";

const CarrouselTexto = ({banners})=> {
    

    if(banners){
        return (
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner carrouselTexto">
                <div className="carousel-item carousel-item-texto active" style={{background: banners[0].BackgroundColor}}>
                    <ContentTextoBanner>
                        <p>{banners[0].Texto}</p>
                    </ContentTextoBanner>
                </div>
                <div className="carousel-item carousel-item-texto"  style={{background: banners[1].BackgroundColor}}>
                    <ContentTextoBanner>
                        <p>{banners[1].Texto}</p>
                    </ContentTextoBanner>

                </div>
                <div className="carousel-item carousel-item-texto"  style={{background: banners[2].BackgroundColor}}>
                    <ContentTextoBanner>
                        <p>{banners[2].Texto}</p>
                    </ContentTextoBanner>

                </div>
            </div>
        </div>
        )
    }

    return (
        <div></div>
    )
}

export default CarrouselTexto