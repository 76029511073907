import axios from "axios";
import { EndPointApi, Credencials } from "./config";



export const get_ProductosByCategoria_PerPage = async(page, categoria) => {

    try {
        const {data} = await axios({
            method: 'GET',
            url: `${EndPointApi}/Productos/getByEstadoAndCategoria/${categoria}/1/${page}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Obtiene todos los Productos de una categoria
export const get_Productos_ByCategoria = async(categoria) => {
    let page = 0
    let productos = []
    let productosEsperados = 25

    while (productosEsperados === 25) {
 
        const result = await get_ProductosByCategoria_PerPage(page, categoria)
        if(result){
            for (const unProducto of result) {
                productos.push(unProducto)
            }
            page = page + 1
            productosEsperados = result.length
        } else {
            productosEsperados = 0
            productos = null
        }

        
    }
    return productos

}

// Obtiene un producto por su Sicoi
export const get_ProductoBySicoi = async(sicoi) => {
    try {
        const {data} = await axios({
            url: `${EndPointApi}/Producto/getBySicoi/${sicoi}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Obtiene los resultados de una busqueda de Productos
export const search_ProductosBySearch = async(search) => {
    try {
        const {data} = await axios({
            method:'POST',
            url:`${EndPointApi}/Productos/Search/${search}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}


