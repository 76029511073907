import React, { useState, useEffect } from 'react';
import { Navigate, useSearchParams } from "react-router-dom";

import useFetch from '../hooks/UseFetch'
import { get_ValorDolar } from "../services/CotizacionDolar";

import {TituloPrincipal  } from "../components/Titulos/Titulos";
import BusquedaForm from '../components/Busqueda/Busqueda'
import TituloBusqueda from '../components/Busqueda/TituloResultadoBusqueda'
import SinResultados from '../components/Busqueda/SinResultados'
import CardsProducto from "../components/CardsProductos/CardsProductos";

import Loading from "../components/Loading/Loading";

const Busqueda = () => {

    const [searchParams] = useSearchParams()
    const type = searchParams.get('search')
    const [loading, setLoading] = useState(false)
    const [precioUDS, setPrecioUDS] = useState()

    useEffect(()=> {
        const getData = async() => {

            setLoading(true)
            const dolar = await get_ValorDolar()
            if(dolar){
                setPrecioUDS(dolar)
            }
            setLoading(false)
        }

        getData()

    },[type])

    const getData = () => {
        return useFetch({
            search: type
        })
    }

    const { isLoading, error, data } = getData()


    if(isLoading || loading) return (<Loading />)

    if(error) return (<Navigate to='/Error' />)

    if(data){
        return (
            <>
                <TituloPrincipal texto='Resultados de tu búsqueda' />
    
                <BusquedaForm />

                <div className="container">
                    
                    { data.length === 0 ? 
                        <SinResultados title={type} />
                        :
                        <>
                        <TituloBusqueda title={type} />
                            <div className="row">
                                {
                                    data.map(unProducto => (
                                        <CardsProducto producto={unProducto} key={unProducto.Id} precioDolar={precioUDS} />
                                    ))
                                }
                            </div>
                        </>


                    }

                </div>
    
            </>
        )
    }

    return (
        <></>
    )
   
}

export default Busqueda