import React from 'react';

import EnConstruccion from "../assets/Images/sitioEnConstruccion.svg";
import { ContentMensajeEnMantenimiento } from "../components/Errors/Estilos";

const EnMantenimiento = () => {
    return (
        <ContentMensajeEnMantenimiento>
            <div>
                <img src={EnConstruccion}  alt="" />
                <h3>Ups...</h3>
                <p>Estamos realizando mejoras en nuestro sitio. <br /> Pronto podras volver a visitar nuestra tienda online.</p>
            </div>
        </ContentMensajeEnMantenimiento>
    ) 
}



export default EnMantenimiento;