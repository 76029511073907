import React, {Suspense} from 'react';
import { Link } from "react-router-dom";
import { generaUnPrecio } from "../../helpers/generaPrecios";

import { FormatNumero } from "../CardsCarrito/CardsCarrito";


import { 
    ContentCardProductos, 
    ImagenProducto, 
    ContentInfoCategoria,
    ContentNombreProducto,
    ContentPrecioProducto,
    IconoVerMas
} from "./Estilos";

import SinFoto from "../../assets/Images/sin_foto.png";

const CardsProductos = ({producto, precioDolar}) => {

    const valorPrecio = generaUnPrecio(0, producto.Precio, precioDolar)


    return (
        <Suspense fallback={<div>Cargando</div>}>
        <div className="col-md-3 col-6 mt-3 mb-3 ">
            <ContentCardProductos>
                <Link to={`/Producto/${producto.CodigoSicoi}`}>
                    {
                        producto.Imagenes.length > 0  ?

                        <>
                            <ImagenProducto src={`https://api.ruedadigital.com.ar/${producto.Imagenes[0].URLImagen}`}  alt={`Rueda Digital Producto ${producto.Nombre}`} />
                        </>
                        :
                        <>  
                            <ImagenProducto src={SinFoto} alt={`Rueda Digital Producto ${producto.Nombre}`} />
                        </>
                    
                    }
                    <div>
                        <ContentInfoCategoria>
                            <span>
                                {producto.Grupo.Nombre}
                            </span>
                        </ContentInfoCategoria>

                        <ContentNombreProducto>
                            <p>
                                {producto.Nombre} 
                            </p>
                        </ContentNombreProducto>

                        <div className="row mt-2">
                            <div className="col-md-8 col-8 align-self-center">
                                <ContentPrecioProducto>
                                    <FormatNumero numero={valorPrecio} />
                                </ContentPrecioProducto>

                                <ContentPrecioProducto>
                                    {
                                        parseInt(producto.MetrosEstandard) === 1 ?
                                        <span>Precio por unidad.</span>
                                        :
                                        <span>Precio por rollo de { producto.MetrosEstandard.slice(0, -3)} mts.</span>
                                    }
                                    
                                </ContentPrecioProducto>
                            </div>
                            <div className="col-md-4 col-4  align-self-center">
                                <IconoVerMas className="bi bi-plus-circle-fill" />
                            </div>
                            <div className="col-md-12">
                                
                            </div>
                        </div>

                    </div>


                </Link>
            </ContentCardProductos>
        </div>
        </Suspense>
    );
}



export default CardsProductos;