import styled  from 'styled-components';

export const ContentCardConfirmPago = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px var(--Color-Green-Aproved);
    border-radius: 24px;
    padding: 25px 50px;
    margin-top: 2%;
    margin-bottom: 2%;

`

export const ImgLogoRueda = styled.img`
    width: 100%;
`

export const CapsulaEcommerce = styled.div`
    background-color: var(--Color-Red-Primary);
    border-radius: 44px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;

    &>p{
        font-family: var(--Font-DMSans-Regular);
        font-style: normal;
        font-size: 16px;
        color: var(--Color-Blanco);
        text-align: center;
        margin-bottom: 0;
    }

`

export const TituloConfirm = styled.p`
    font-size: 20px;
    font-family: var(--Font-DMSans-Bold);
    color: var(--Color-Green-Aproved);
    text-align: center;
`

export const MensajeConfirm = styled.p`
    font-size: 16px;
    font-family: var(--Font-DMSans-Regular);
    width: 90%;
    margin-left: 5%;

`

export const ContentCardErrorPago = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px var(--Color-Red-Primary);
    border-radius: 24px;
    padding: 25px 50px;
    margin-top: 2%;
    margin-bottom: 2%;
`

export const TituloError = styled.p`
    font-size: 20px;
    font-family: var(--Font-DMSans-Bold);
    color: var(--Color-Red-Primary);
    text-align: center;
`

export const ContentCardPendingPago = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px #FFFF00;
    border-radius: 24px;
    padding: 25px 50px;
    margin-top: 2%;
    margin-bottom: 2%;
`