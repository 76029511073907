import React from 'react';
import { Modal } from 'reactstrap';

import LogoRuedaImg from "../../assets/Logos/logo.svg";

import {
  ContentModal,
  ContentMensajeError,
  ContentMensajeExito,
  ContentMensajeUserRegister,
  ImgLogoRueda,
  CapsulaEcommerce,
  BienvenidoText,
  SubTituloBienvenido
} from "./Estilos";


import { IrAlInicioBotonFondoRojo, BotonPrimarioFondoRojo } from "../Botones/Botones";

export const ModalErrorContacto = ({ titulo, message, icono, data }) => {

  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeError>
          <div>
            <i className={icono}></i>
          </div>
          <div>
            <h2>{titulo}</h2>
          </div>
          <div>
            <p>{message}</p>
          </div>

          <div className="row p-4">
            <div className="col-md-6 mx-auto">
              <IrAlInicioBotonFondoRojo
                link='/'
                texto='Ir al inicio'
              />
            </div>
          </div>


        </ContentMensajeError>

      </Modal>
    </ContentModal>

  )
}

export const ModalExito = ({ titulo, message, icono, data }) => {

  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeExito>
          <div>
            <i className={icono}></i>
          </div>
          <div>
            <h2>{titulo}</h2>
          </div>
          <div>
            <p>{message}</p>
          </div>

          <div className="row">
            <div className="col-md-6 mx-auto">
              <IrAlInicioBotonFondoRojo
                link='/'
                texto='Ir al inicio'
              />
            </div>
          </div>


        </ContentMensajeExito>

      </Modal>
    </ContentModal>

  )
}



export const ModalUsuarioNoRegistrado = ({ titulo, message, icono, data }) => {

  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeError>
          <div>
            <i className={icono}></i>
          </div>
          <div>
            <h2>{titulo}</h2>
          </div>
          <div>
            <p>{message}</p>
          </div>

          <div className="row p-4">
            <div className="col-md-4 col-6">
              <BotonPrimarioFondoRojo
                link='/Ingresar'
                texto='Ingresar'
              />
            </div>
            <div className="col-md-4 col-6">
              <BotonPrimarioFondoRojo
                link='/Registrarme'
                texto='Registrate'
              />
            </div>
            <div className="col-md-4 col-6 mt-2 mx-auto">
              <BotonPrimarioFondoRojo
                link='/'
                texto='Ir al inicio'
              />
            </div>
          </div>


        </ContentMensajeError>

      </Modal>
    </ContentModal>

  )
}


export const ModalRegistroUsuario = ({ data }) => {
  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeUserRegister>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                  <CapsulaEcommerce>
                    <p>E-Commerce</p>
                  </CapsulaEcommerce>
                </div>
              </div>

              <div className="row mb-2 mt-4">
                <div className="col-md-12">
                  <BienvenidoText>
                    Ya casi está listo
                  </BienvenidoText>
                  <SubTituloBienvenido>
                    Antes de ingresar a tu cuenta debes validar la misma verificando tu correo y clickeando sobre el link que te hemos enviado.
                  </SubTituloBienvenido>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <IrAlInicioBotonFondoRojo
                    link='/'
                    texto='Ir al inicio'
                  />
                </div>
              </div>

            </div>

          </div>



        </ContentMensajeUserRegister>

      </Modal>
    </ContentModal>

  )
}


export const ModalCierreSesion = ({ data }) => {
  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeUserRegister>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                  <CapsulaEcommerce>
                    <p>E-Commerce</p>
                  </CapsulaEcommerce>
                </div>
              </div>

              <div className="row mb-2 mt-4">
                <div className="col-md-12">
                  <BienvenidoText>
                    Gracias por tu visita.
                  </BienvenidoText>
                  <SubTituloBienvenido>
                    Hemos cerrado tu sesión de forma correcta.
                  </SubTituloBienvenido>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div onClick={data = false}>
                    <IrAlInicioBotonFondoRojo
                      link='/'
                      texto='Ir al inicio'
                    />
                  </div>

                </div>
              </div>

            </div>

          </div>



        </ContentMensajeUserRegister>

      </Modal>
    </ContentModal>

  )
}



export const ModalInfoUsuario = ({ data, titulo, texto, login }) => {
  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeUserRegister>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                  <CapsulaEcommerce>
                    <p>E-Commerce</p>
                  </CapsulaEcommerce>
                </div>
              </div>

              <div className="row mb-2 mt-4">
                <div className="col-md-12">
                  <BienvenidoText>
                    {titulo}
                  </BienvenidoText>
                  <SubTituloBienvenido>
                    {texto}
                  </SubTituloBienvenido>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {login === true ?
                    <IrAlInicioBotonFondoRojo
                      link='/Ingresar'
                      texto='Ingresar'
                    />
                    :

                    <IrAlInicioBotonFondoRojo
                      link='/'
                      texto='Ir al inicio'
                    />
                  }

                </div>
              </div>

            </div>

          </div>



        </ContentMensajeUserRegister>

      </Modal>
    </ContentModal>

  )
}


export const ModalProductoAgregado = ({titulo, data, message, error, link, texto}) => {
  return (
    <ContentModal>
    <Modal isOpen={data}>
        <ContentMensajeUserRegister>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                  <CapsulaEcommerce>
                    <p>E-Commerce</p>
                  </CapsulaEcommerce>
                </div>
              </div>
  
              <div className="row mb-2 mt-2">
                <div className="col-md-12">
                  <BienvenidoText>
                    {titulo}
                  </BienvenidoText>
                  <SubTituloBienvenido>
                    {message}
                  </SubTituloBienvenido>
                </div>
              </div>
  
              <div className="row">
              { error === true ?
                null
                :
                <>
                  <div className="col-md-6 col-6">
                      <BotonPrimarioFondoRojo
                        link={link !== '' ? link : '/UserCarrito' }
                        texto={texto !== '' ? texto : 'Ir a mi carrito'}
                      />
                  </div>
                </>
              }

                  <div className="col-md-6 col-6">
                    <IrAlInicioBotonFondoRojo
                      link='/'
                      texto='Ir al inicio'
                    />
                  </div>
              </div>
  
            </div>
  
          </div>
  
  
  
        </ContentMensajeUserRegister>
  
    </Modal>
  </ContentModal>
  )
}


export const ModalOrdenAgregada = ({data}) => {
  return (
    <ContentModal>
    <Modal isOpen={data}>
        <ContentMensajeUserRegister>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ImgLogoRueda src={LogoRuedaImg} alt='Rueda Digital' />
                  <CapsulaEcommerce>
                    <p>E-Commerce</p>
                  </CapsulaEcommerce>
                </div>
              </div>
  
              <div className="row mb-2 mt-4">
                <div className="col-md-12">
                  <BienvenidoText>
                     Todo ha ido bien.
                  </BienvenidoText>
                  <SubTituloBienvenido>
                    Hemos agregado una orden a tu perfil de forma correcta, la misma la tendras disponible en tu panel de usuario.
                  </SubTituloBienvenido>
                </div>
              </div>
  
              <div className="row">
                <div className="col-md-6">
                  <BotonPrimarioFondoRojo
                    link='/UserPanel'
                    texto='Ir a mi panel'
                  />
                  </div>

              </div>
  
            </div>
  
          </div>
  
  
  
        </ContentMensajeUserRegister>
  
    </Modal>
  </ContentModal>
  )
}
