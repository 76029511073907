import styled, {keyframes} from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;


export const ContentCardLoginForm = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 50px;

    @media (max-width: 480px) {
        width: 95%;
        margin: auto;
    }

`

export const ImgLogoRueda = styled.img`
    width: 100%;
    
    @media (max-width: 480px) {
        width: 50%;
        margin-left: 0;
    }
`

export const CapsulaEcommerce = styled.div`
    background-color: var(--Color-Red-Primary);
    border-radius: 44px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;

    &>p{
        font-family: var(--Font-DMSans-Regular);
        font-style: normal;
        font-size: 16px;
        color: var(--Color-Blanco);
        text-align: center;
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        width: 60%;
        &>p{
            font-size: 12px;
        }
    }

`

export const BienvenidoText = styled.p`
    font-family: var(--Font-Roboto-Regular);
    color: var(--Color-Red-Primary);
    font-weight: 700;
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 0.2px;
    margin-bottom: 5px;

    @media (max-width: 480px) {
        font-size: 20px;
    }
`

export const SubTituloBienvenido = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 16px;

    & > span {
        font-size: 14px;
        color: var(--Color-Grey-Dark);
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }

`

export const LabelForm = styled.label`
    font-family: var(--Font-DMSans-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
    width: 90%;
    margin-left: 2%;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`

export const ContentMsgError = styled.div`
    animation: ${opacity} 1s ease;
    position: relative;
`

export const ErrorValueInput = styled.span`
    color: var(--Color-Red-Primary);
    font-family:var(--Font-DMSans-Regular);
    margin-left: 2%;
    font-weight:bold;
    font-size: 12px;

    @media (max-width: 480px) {
        font-size: 10px;
    }
    
`

export const TenesCuenta = styled.p`
    color: var(--Color-Grey-Dark);
    font-family:var(--Font-DMSans-Regular);
    font-size: 12px;
    @media (max-width: 480px) {
        text-align: center;
    }
    
`

export const ContentOlvidePass = styled.div`
    margin-top: 2%;
    width: 50%;
    margin-left: 50%;
    text-align: right;

    & > a {
        text-decoration: none;
        color: var(--Color-Grey-Dark);
        font-size: 12px;
    }

    & > a:hover{
        color: var(--Color-Red-Primary);
    }

    @media (max-width: 480px) {
        margin-bottom:5%;
    }
`