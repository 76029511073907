import React from 'react'

import NotFound from "../../assets/Images/notFound.svg";

import { CardMensaje } from "./Estilos";

import { ReturnRedButton } from "../Botones/Botones";

const SinDatos = ({title, message}) => {
  return (
      <div className="container p-4">
          <div className="row p-4">
            <div className="col-md-6">
                <img src={NotFound} className='w-100' alt="No encontrado" />
            </div>
            <CardMensaje className='col-md-6'>
                <h2>
                    {title}
                </h2>
                <p>
                    {message}
                </p>

                <div className='mt-4'>
                    <ReturnRedButton />
                </div>

            </CardMensaje>
          </div>

      </div>


  )
}

export default SinDatos