import styled from 'styled-components';

export const ContentFooter = styled.div `
    margin-top: 5%;
    background-color: #2d3436;
    margin-bottom: 0;
    padding: 20px;
`

export const TitulosFooter = styled.p`
    font-family: var(--Font-DMSans-Bold);
    color: var(--Color-Blanco);
    text-align: left;
    font-size: 16px;
`

export const TextoFooter = styled.p`
    font-family: var(--Font-DMSans-Regular);
    color: var(--Color-Grey-Light);
    text-align: left;
    font-size: 14px;
`

export const ContentLink = styled.div`
    margin-bottom: 2%;
    &>a{
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Grey-Light);
        text-align: left;
        font-size: 14px;
        text-decoration: none;
        transition: all .25s ease-in;
    }

    &>a:hover{
        color: var(--Color-Blanco)
    }
`