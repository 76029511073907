import styled from 'styled-components';

export const ContentCardUserPanelAlert = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px var(--Color-Red-Primary);
    border-radius: 24px;
    padding: 50px;    
`

export const TituloCard = styled.p`
    font-family: var(--Font-DMSans-Regular);
    text-align: center;
    color: var(--Color-Negro);
    font-size: 18px;

`

export const MsgCard = styled.p`
    font-family: var(--Font-DMSans-Regular);
    text-align: center;
    color: var(--Color-Red-Primary);
    font-size: 16px;

`

export const SubMsgCard = styled.p`
    font-family: var(--Color-Grey-Dark);
    text-align: center;
    color: var(--Color-Negro);
    font-size: 14px;

`


export const ContentCardUserPanelNormal = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 50px;
    transition: all .5s linear;
    
    &:hover{
        box-shadow: 0px 4px 30px -4px var(--Color-Red-Primary);
        transform: scale(.9);
    }

    &>a{
        text-decoration: none;
        width: 100%;
    }
`

export const SpanPanelUser = styled.div`
    width: 100%;
    text-align: center;

    & > h3 {
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Negro);
        font-size: 20px;
        margin-bottom: 0;
    }

    & > i {
        color: var(--Color-Red-Primary);
        font-size: 46px;

    }

    & > p {
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Grey-Dark);
        font-size: 14px;
        margin-bottom: 0;
    }
`