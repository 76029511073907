import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { get_Token } from "../helpers/getToken";

import RegisterForm from "../components/LoginForm/RegisterForm";

const Registro = () => {

    useEffect(()=>{
        const data = () =>{
            const token = get_Token()
            if(token){
                window.location.href='/UserPanel'
            }
        }
        data()
    },[])


    return (
        <>
            <Helmet>
                <title>Rueda Digital - Registrarme</title>
            </Helmet>

            <RegisterForm />
            
        </>
    ) 
}



export default Registro;