import React, { useEffect, useState} from 'react';

import { get_OrdenesCliente } from "../services/Users";
import { get_Token } from "../helpers/getToken";

import Loading from "../components/Loading/Loading";
import {TituloPrincipal  } from "../components/Titulos/Titulos";
import CardsOrdenes from "../components/CardsOrdenes/CardsOrdenes";

const UserOrdenes = () => {

    const [loading, setLoading] = useState(false)
    const [ordenes, setOrdenes] = useState()
    const [token, setToken] = useState(null)



    useEffect(()=>{
        const getData = async() => {
            setLoading(true)
            const token = get_Token()
            if(!token){
                window.location.href='/Ingresar'
            }else{
                setToken(token.token)
                const result = await get_OrdenesCliente(token.token)
                if(result){
                    setOrdenes(result)
                }else{
                    setOrdenes(null)
                }
            }
            setLoading(false)

        }
        getData()
    }, [])


    return (
        <> 
            { loading ?
                <Loading />
                :
                <>
                    <TituloPrincipal texto='Mis ordenes'  />


                    <CardsOrdenes ordenes={ordenes} token={token}/>
 
                
                </>
            }
        
        
        </>
    );
}



export default UserOrdenes;