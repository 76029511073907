import React, {useState, useEffect} from "react";
import {  UncontrolledCarousel } from 'reactstrap';


const CarrouselImagenes = ({banners})=> {

    const [dataBanners, setbanners] = useState([])

    useEffect(()=>{
        const ajustaBanners = async (banners) =>{
            const result = []
            if(banners){
                for (const unBanner of banners) {
                    if(unBanner.Activo === true){
                        const banner = {
                            key: result.length + 1,
                            src: `https://api.ruedadigital.com.ar/${unBanner.URLImagen}`
                        }

                        result.push(banner)
                    }

                }
            }

            setbanners(result)

        }

        ajustaBanners(banners)
    },[])

    return (

        <UncontrolledCarousel
            items={dataBanners}
        />


    )
}

export default CarrouselImagenes


