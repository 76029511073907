import React from 'react';
import { Link } from 'react-router-dom';

import { TituloResultado } from './Estilos'

const SinResultadoBusqueda = ({title}) => {
    return (
        <div className="mb-4 p-4">
            <TituloResultado>
                <p>Lo sentimos no hemos encontrados resultados <br /> para tu búsqueda <span>"{title}"</span>. </p>
                <br />
                <p>Puedes realizar una nueva búsqueda o <Link to='/Contacto'>Contactarte </Link>con unos de nuestros representantes.</p>
            </TituloResultado>

            

        </div>

    )
}

export default SinResultadoBusqueda