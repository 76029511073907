import React from 'react';

import { MensajeErrorNotFound } from "../components/Errors/Estilos";

import { ReturnRedButton } from "../components/Botones/Botones";

const NotFound = () => {
    return(
        <MensajeErrorNotFound>
            <h3>Ups! <br /> Página no encontrada</h3>
            <p>Parece que ha habido un error con la página que estas buscando. <br /> Es posible que la página haya sido eliminada o que la dirección no exista.</p>
            <div className="row mt-4">
                <div className="col-md-8 mx-auto">
                    <ReturnRedButton />
                </div>

            </div>

        </MensajeErrorNotFound>
    );
}



export default NotFound;