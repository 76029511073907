import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";

import { get_Token } from "../helpers/getToken";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import RecuperaPassForm from "../components/LoginForm/RecuperaPassForm";

const RecuperaPassword = () => {

    useEffect(()=>{
        const data = () =>{
            const token = get_Token()
            if(token){
                window.location.href='/UserPanel'
            }
        }
        data()
    },[])


    return(
        <>
            <Helmet>
                <title>Rueda Digital - Recuperar contraseña</title>
            </Helmet>
            
            <TituloPrincipal texto='Recuperar contraseña' />

            <RecuperaPassForm />
        </>
    );
}



export default RecuperaPassword;