import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const user_Register = async(body) => {
    try {
        const result = await axios({
            method: 'POST',
            url:`${EndPointApi}/Clientes/addOne`,
            auth: Credencials,
            data: body
        })

        if(result.data){
            if(result.data.status === 'OK'){
                return {
                    status:'OK',
                    msg: ''
                }
            }

            if(result.data.status === 'ERROR'){
                return {
                    status: 'ERROR',
                    msg: result.data.errors
                }
            }
        }

        return {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
        
    } catch (error) {
        return {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
    }
}

export const user_ValidMail = async(mail, hash) => {
    try {
        const result = await axios({
            method:'PUT',
            url:`${EndPointApi}/Clientes/validMail/${mail}/${hash}`,
            auth: Credencials
        })

        if(result){
            if(result.data.status === 'OK'){
                return true
            }
        }

        return false
        
    } catch (error) {
        return false
    }
}

export const user_Login = async(mail, pass) => {
    try {
        const result = await axios({
            method:'GET',
            url:`${EndPointApi}/Clientes/getByMailAndPass/${mail}/${pass}`,
            auth: Credencials
        })

        if(result){
            if(result.data.status === 'OK'){
                return {
                    status: 'OK',
                    token: result.data.data.token,
                    name: `${result.data.data.nombre} `
                }
            }
            
            if(result.data.status === 'ERROR'){
                return {
                    status: 'ERROR',
                    msg: result.data.errors
                }
            }
        }


        return  {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
        
    } catch (error) {
        return  {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
    }
}

export const recuperar_Password = async(mail) => {
    try {
        const result = await axios({
            method:'PUT',
            url: `${EndPointApi}/Clientes/addHashForgotPass/${mail}`,
            auth: Credencials
        })

        if(result){
            if(result.data.status === 'OK'){
                return {
                    status: 'OK'
                }
            }
            
            if(result.data.status === 'ERROR'){
                return {
                    status: 'ERROR',
                    msg: result.data.errors
                }
            }
        }


        return  {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
        
    } catch (error) {
        return {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
    }
}

export const cambiar_Password = async(body, mail) => {
    try {
        const result = await axios({
            method:'PUT',
            url:`${EndPointApi}/Clientes/CambiaPass/${mail}`,
            auth:Credencials,
            data: body
        })
        
        if(result){
            if(result.data.status === 'OK'){
                return {
                    status: 'OK'
                }
            }
            
            if(result.data.status === 'ERROR'){
                return {
                    status: 'ERROR',
                    msg: result.data.errors
                }
            }
        }


        return  {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }

        
        
    } catch (error) {
        return {
            status: 'ERROR',
            msg: 'Ah ocurrido un error, por favor vuelve a intentarlo en unos minutos.'
        }
    }
}

export const get_PerfilUsuario = async(token) => {
    try {
        const result = await axios({
            method: 'GET',
            url:`${EndPointApi}/PerfilCliente/getOne`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            }
        })

        if(result){
            if(result.data.status === 'OK'){

                return {
                    status: 'OK',
                    data: result.data.data
                }
            }
            
            if(result.data.status === 'ERROR'){
                return {
                    status: 'ERROR',
                    msg: result.data.errors
                }
            }
        }

        return null

    } catch (error) {
        return null
    }
}

export const get_CondiocionesIVA = async() => {
    try {
        const result = await axios({
            method:'GET',
            url: `${EndPointApi}/CondicionesFrenteAlIva/getAll`,
            auth:Credencials
        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null

    } catch (error) {
        return null
    }
}


export const edit_PerfilUsuario = async(token, body) => {
    try {
        const result = await axios({
            method:'POST',
            url:`${EndPointApi}/PerfilCliente/addorEdit`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data:body

        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

export const get_Carrito = async(token) => {
    try {
        const result = await axios({
            method:'POST',
            url: `${EndPointApi}/Carrito/getCarrito`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            }
        })

        if(result.data.status === 'OK'){
            return {
                carrito: result.data.data.carrito,
                items: result.data.data.items,
            }
        }
        
    } catch (error) {
        return null
    }
}

export const edita_CarritoUsuario = async(token, body) => {
    try {
        const result = await axios({
            method:'POST',
            url: `${EndPointApi}/Carrito/editCarrito`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data:body

        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

export const generaOrden = async(token, body) => {
    try {
        const result = await axios({
            method:'POST',
            url:`${EndPointApi}/Ordenes/createNew`,
            auth:Credencials,
            headers:{
                "Auth-Token": token
            },
            data:body
        })

        if(result.data.status === 'OK'){
            return {
                status: 'OK',
                data: result.data.data
            } 
        }else{
            return {
                status: 'ERROR',
                msg: result.data.errors
            }
        }


        
    } catch (error) {
        return null
    }
}

export const get_OrdenesCliente = async(token) =>{
    try {
        const result = await axios({
            method:'GET',
            url: `${EndPointApi}/Ordenes/Client`,
            auth:Credencials,
            headers:{
                "Auth-Token": token
            }
        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

export const get_OrdenCliente = async(token, hashOrden) => {
    try {
        const result = await axios({
            method:'GET',
            url:`${EndPointApi}/Ordenes/getOne/${hashOrden}`,
            auth:Credencials,
            headers:{
                "Auth-Token": token
            }
        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

export const add_Payment = async(hash, body) => {
    try {
        const result = await axios({
            method:'POST',
            url:`${EndPointApi}/Ordenes/addDetallePago/${hash}`,
            auth:Credencials,
            data: body

        })

        if(result.data.status === 'OK'){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

