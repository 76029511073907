import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const getAll_BannersImagen = async() => {
    try {
        const resultado = await axios({
            method:'GET',
            url: `${EndPointApi}/BannersImagenes/getAll`,
            auth: Credencials
        })

        if(resultado){
            return resultado.data
        }

        return null
        
    } catch (error) {
        return null 
    }
}


export const getAll_BannersTexto = async() => {
    try {
        const resultado = await axios({
            method:'GET',
            url: `${EndPointApi}/bannersTexto/getAll`,
            auth: Credencials
        })

        if(resultado){
            return resultado.data
        }

        return null
        
    } catch (error) {
        return null 
    }
}