import React from 'react';

import { ContentCardsAyuda, RespuestaPregunta } from "./Estilos";

const UnaAyuda = ({unaPregunta}) => {
    return (
        <div className="accordion-item">
        <h2 className="accordion-header" id={`panelsStayOpen-heading${unaPregunta.Id}`}>
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${unaPregunta.Id}`} aria-expanded="true" aria-controls={`panelsStayOpen-heading${unaPregunta.Id}`} >
                <i className='bi bi-bookmark-plus-fill'></i>
                <span className='span-accordion'>{unaPregunta.Pregunta}</span> 
            </button>
        </h2>
        <div id={`panelsStayOpen-collapse${unaPregunta.Id}`} className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
            <div className="accordion-body">
                <RespuestaPregunta>
                    {unaPregunta.Respuesta}
                </RespuestaPregunta>
                {
                    unaPregunta.Parrafo1 ? <RespuestaPregunta> {unaPregunta.Parrafo1} </RespuestaPregunta> : null

                }

                {
                    unaPregunta.Items ? 
                        <ul>
                            {unaPregunta.Items.map(unItem => (
                                <li key={unItem.Id} className='item-ayuda'>{unItem.Item}</li>
                            ))}
                        </ul>
 
                    :
                    null
                }
            </div>
        </div>
    </div>
    )
}


const CardsAyuda = ({ preguntas }) => {
    return (
        <div className="row mt-4">
            <div className="col-md-8 col-12 mx-auto">
                <ContentCardsAyuda>
                    <div className="accordion" id="accordionPanelsStayOpenExample">

                    {
                        preguntas.map(unaPregunta => (
                            <UnaAyuda unaPregunta={unaPregunta} key={unaPregunta.Id} />
                        ))
                    }
                    </div>
                </ContentCardsAyuda>

            </div>
        </div>

    );
}



export default CardsAyuda;