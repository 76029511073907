import React from 'react';
import { Link } from 'react-router-dom';
import LogoRuedaImg from "../../assets/Logos/logo.svg";


import { ContentItemOrden, IdOrden, TotalOrden, BtnVerOrdenes, MessageSinOrden, ImgRueda, LogoEnOrden } from "./Estilos";

import SinDatos from "../Errors/SinDatos";

const Botonera = () => {
    return (
        <div className="row">
        <div className="col-md-6 col-11 mx-auto">
            <hr />
            <div className="row">
                <div className="col-md-6 col-6">
                    <BtnVerOrdenes>
                        <Link to='/UserPanel'>
                            Ir a mi panel
                        </Link>
                    </BtnVerOrdenes>
                </div>
                <div className="col-md-6 col-6">
                    <BtnVerOrdenes>
                        <Link to='/'>
                            Ir a Inicio
                        </Link>
                    </BtnVerOrdenes>
                </div>

            </div>
        </div>
    </div>
    )
}

const FormatNumero = ({ numero }) => {
    const splitNumero = numero.split('.')

    return (
        <>
            <TotalOrden>
                Total Orden: <small>ARS $</small><span>{splitNumero[0]}</span>,<sup>{splitNumero[1]}</sup>
            </TotalOrden>
        </>
    )
}

const FormatFecha = ({ fecha }) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <>
            <TotalOrden>
                Fecha: <span>{new Date(fecha).toLocaleDateString("es-ES", options)}</span>
            </TotalOrden>
        </>
    )
}

const EstadoOrden = ({ estado }) => {


    return (
        <>
            <TotalOrden>
                Estado: <span>{estado}</span>
            </TotalOrden>
        </>
    )
}

const CardsOrdenes = ({ ordenes }) => {


    return (
        <>
            <div className="row mt-4">
                <div className="col-md-12 mx-auto">

                    {ordenes ?
                        <>
                            {ordenes.length === 0 ?
                                <>  
                                    <div className="row">
                                        <div className="col-md-6 mx-auto">
                                            
                                            <ContentItemOrden>
                                                <ImgRueda src={LogoRuedaImg} />
                                                <hr />
                                                <MessageSinOrden>
                                                    Todavía no haz generado ninguna orden.
                                                </MessageSinOrden>
                                                <small>Si hace poco generaste una, vuelve en unos minutos y la tendrás disponible para verla.</small>
                                            </ContentItemOrden>
                                        </div>
                                    </div>

                                    <Botonera />
                                </>
                                :

                                <>
                                    <div className="row mb-4">
                                        <div className="col-md-6  mx-auto">
                                            {
                                                ordenes.map(unaOrden => (
                                                    <ContentItemOrden key={unaOrden.Id}>
                                                        <div className="row">
                                                            <div className="col-md-3 col-10 mx-auto mb-3 align-self-center">
                                                                <LogoEnOrden src={LogoRuedaImg} alt="Rueda Digital Logo" />
                                                            </div>
                                                            <div className="col-md-6 col-10 mb-3 mx-auto mt-2">
                                                                <IdOrden>Orden Id: <span>{unaOrden.Id.toString().padStart(4, "0000")}</span> </IdOrden>
                                                                <FormatNumero numero={unaOrden.TotalOrden} />
                                                                <FormatFecha fecha={unaOrden.CreatedAt} />
                                                                <EstadoOrden estado={unaOrden.EstadoPago} />
                                                            </div>
                                                            <div className="col-md-3 col-8 mx-auto mt-2 align-self-center">
                                                                <BtnVerOrdenes>
                                                                    <Link to={`/UserOrden/${unaOrden.HashOrden}`}>

                                                                        <i className="bi bi-eye"></i>
                                                                        Ver Orden
                                                                    </Link>
                                                                </BtnVerOrdenes>

                                                            </div>
                                                        </div>
                                                    </ContentItemOrden>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <Botonera />


                                </>

                            }

                        </>
                        :
                        <>
                            <SinDatos
                                title='Ups ...'
                                message='No hemos podido recuperar tus datos. Por favor vuelvelo a intentar en unos minutos.'
                            />
                        </>

                    }


                </div>
            </div>




        </>

    );
}



export default CardsOrdenes;