import Cookies from "universal-cookie";

const cookies = new Cookies();
export const get_Token = () => {
    if(cookies.get('user_rueda')){
        return {
            token: cookies.get('user_rueda'),
            name: cookies.get('name_rueda')
        } 
    }else{
        return null
    }
}


export const get_DataToken = () => {
    if(cookies.get('user_rueda')){
        return {
            token: cookies.get('user_rueda'),
            name: cookies.get('name_rueda')
        } 
    }else{
        window.location.href='/Ingresar'
    }
}

