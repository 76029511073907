import styled, {keyframes} from 'styled-components';


export const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`

export const ContentHome = styled.div`
    animation: ${opacity} 1s ease;

`

export const ContentLinkVerTodas = styled.div`
    width: 100%;
    text-align: right;
    color:  var(--Color-Grey-Dark);
    margin-bottom: 5px;

    & > a {
        text-decoration: none;
        color: var(--Color-Red-Primary);
        font-family: var(--Font-DMSans-Bold);
        font-size: 12px;
        transition: all .25s ease-in;
    }


    &:hover{
        & > a{
            color: var(--Color-Grey-Dark)
        }

    }

    @media (max-width: 480px) {
        & > a {
            font-size: 12px;
        }
    }

`

export const ContentProductosInicio = styled.div`
    background-color: var(--Color-Grey-Light);
    padding: 25px 50px;
    border-radius: 50px;

    @media (max-width: 480px) {
        width: 95%;
        margin: auto;
        padding: 25px 10px;
        border-radius: 15px;
    }

`

export const ContentTextoBanner = styled.div`
    width: 100%;
    text-align: center;

    & > p {
        font-size: 22px;
        font-family: var(--Font-DMSans-Regular);
        color: var(--Color-Blanco);
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        &>p{
            font-size: 16px;
        }
    }
`