import React from 'react';

import { 
    ContentCardUserPanelAlert, 
    TituloCard,
    MsgCard, 
    SubMsgCard
} from "./Estilos";

import { BotonPrimarioFondoRojo } from "../Botones/Botones";

const CardsInicio = ({invalid, tipo, msg, submsg, link, texto}) => {

    if(invalid){
        return (
            <ContentCardUserPanelAlert>
                <TituloCard>
                    {tipo}
                </TituloCard>

                <MsgCard>
                    {msg}
                </MsgCard>

                <SubMsgCard>
                    {submsg}
                </SubMsgCard>

                <hr />
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <BotonPrimarioFondoRojo
                            link={link}
                            texto={texto}
                        />
                    </div>
                </div>

            </ContentCardUserPanelAlert>
        )

    }

    return(

        <ContentCardUserPanelAlert>
            <p>{tipo}</p>

        </ContentCardUserPanelAlert>
    );
}



export default CardsInicio;