import React, { useState, useContext, useEffect } from 'react';
import Cookies from "universal-cookie";
import { Link, useLocation } from 'react-router-dom';


import LogoRuedaImg from "../../assets/Logos/logo.svg";

import { BotonPrimarioFondoRojo, BotonPrimarioFondoBlanco } from "../Botones/Botones";

import { LogoRueda, BotonCerrarSesion } from "./Estilos";

import Loading from "../Loading/Loading";

import UserContext from "../../context/userContext";


const cookies = new Cookies();

const Navegacion = () => {

    const location = useLocation()

    const [loading, setLoading] = useState(false)

    const context = useContext(UserContext)

    useEffect(()=>{
        const removeShow = document.querySelector('.collapse')
        removeShow.className = ''
        removeShow.className = 'navbar-collapse collapse'

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    },[location])

    const cerrarSesion = () => {
        setLoading(true)
        cookies.remove('user_rueda', { path: "/" })
        cookies.remove('name_rueda', { path: "/" })
        context.setJWT(null)
        window.location.href = '/Ingresar'
        setLoading(false)
    }

    if (loading) {
        return (
            <Loading />
        )
    }

   
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className='navbar-brand' to='/'>
                    <LogoRueda src={LogoRuedaImg} className='img-responsive' alt='Rueda Digital' />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={location.pathname === '/Inicio' || location.pathname === '/' ? "nav-link active" : "nav-link"} to='/'>                                 
                                Inicio                             
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className={location.pathname.includes('/Productos') || location.pathname.includes('/Producto') ? "nav-link active" : "nav-link"} to='/Productos'>
                                Productos
                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link className={location.pathname.includes('/Contacto') ? "nav-link active" : "nav-link"} to='/Contacto'>
                                Contacto
                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link className={location.pathname.includes('/Ayuda') ? "nav-link active" : "nav-link"} to='/Ayuda'>
                                Ayuda
                            </Link>
                        </li>


                    </ul>
                    {
                        context.jwt === null ?
                        <>
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <BotonPrimarioFondoRojo link='/Ingresar' texto='Ingresar' />
                                </div>
                                <div className="col-md-6 col-6">
                                    <BotonPrimarioFondoBlanco link='/Registrarme' texto='Registrate' />
                                </div>
                            </div>
                        </>
                        :
                        <>
                        <div className="row align-self-center">
                            <div className="col-md-7 col-12 align-self-center">
                                <ul className='content-drop-usuario'>
                                    <li className="nav-item dropdown btn-drop-usuario">
                                            <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span>Hola</span> {context.jwt.name} <i className="bi bi-chevron-down"></i>
                                            </Link>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li>
                                                <Link className='dropdown-item linkOpcionUsuario' to={'/UserCarrito'}>
                                                    Mi Carrito {context.items ?  context.items : '...' }
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className='dropdown-item linkOpcionUsuario' to={'/UserAllPerfil'}>
                                                    Perfil
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className='dropdown-item linkOpcionUsuario' to={'/UserPanel'}>
                                                    Inicio
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <BotonCerrarSesion onClick={() => cerrarSesion()}>
                                    Salir <i className="bi bi-door-closed"></i>
                                </BotonCerrarSesion>
                            </div>
                        </div>

                        </>
                    }

                </div>
            </div>
        </nav>
    )
}


export default Navegacion;



