export const generaUnPrecio = (cantidad, precioProducto, valorDolar) => {
    const qCantidad = (cantidad === 0) ? 1 : cantidad
    const stringDolar = valorDolar.data.venta.replace(',', '.')
    const precio = qCantidad * parseFloat(precioProducto) * parseFloat(stringDolar)

    const stringPrecio = new Intl.NumberFormat('de-DE').format(precio);
    const value = stringPrecio.split(',');
    if (value[1] === undefined) {
        value[1] = '00';
    }
    return `${value[0].replace('.','')},${value[1][0]}${value[1][1]}`;
} 

export const generaPrecioTotal = (productos, valorDolar) => {
    let total = 0
    const stringDolar = valorDolar.data.venta.replace(',', '.')
    for (const unItem of productos) {
        total = total + unItem.Cantidad *unItem.Producto.Precio * parseFloat(stringDolar)
    }

    const stringPrecioTotal = new Intl.NumberFormat('de-DE').format(total);
    const valueTotal = stringPrecioTotal.split(',');
    if (valueTotal[1] === undefined) {
        valueTotal[1] = '00';
    }
    return `${valueTotal[0]},${valueTotal[1][0]}${valueTotal[1][1]}`;
}

export const generaUnPrecioBase = (cantidad, precioProducto, valorDolar) => {

    const qCantidad = (cantidad === 0) ? 1 : cantidad
    const precio = qCantidad * parseFloat(precioProducto)  *parseFloat(valorDolar)

    const stringPrecio = new Intl.NumberFormat('de-DE').format(precio);
    const value = stringPrecio.split(',');
    if (value[1] === undefined) {
        value[1] = '00';
    }
    return `${value[0].replace('.','')},${value[1][0]}${value[1][1]}`;
} 
