import React, { useEffect, useState, useContext } from 'react';
import UserContext from "../../context/userContext";
import { Link } from "react-router-dom";

import { get_Token } from "../../helpers/getToken";
import { generaUnPrecio } from "../../helpers/generaPrecios";


import { get_ProductosByCategoria_PerPage } from "../../services/Productos";
import { edita_CarritoUsuario, get_Carrito } from "../../services/Users";


import CardsProductos from "./CardsProductos";
import SinFoto from "../../assets/Images/sin_foto.png";
import { FormatNumero } from "../../components/CardsCarrito/CardsCarrito";



import {
    ContentCardUnProducto,
    ContentLinkCategoria,
    ImagenPrincipalProducto,
    ContentNombreUnProducto,
    TextoDataUnProducto,
    ContentPrecioUnProducto,
    ContentMessageSinDescripcion,
    ContentProductosRelacionados,
    TitulosProductosRelacionados,
    ContentLinkRelacionados,
    ProductoSinStock,
    TitulosDescripcion,
    TextoDescripcion
} from "./Estilos";

import { RedPrimaryButtonStyles } from "../Botones/Estilos";

import { ModalUsuarioNoRegistrado, ModalProductoAgregado } from "../Modals/Modales";
import Loading from "../Loading/Loading";


const UnProducto = ({ producto, precioUSD }) => {
    const token = get_Token()

    const [relacionados, setRelacionados] = useState([])
    const [ModalNoRegistrado, setModalNoRegistrado] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalProducto, setModalProducto] = useState(false)
    const [titulo, setTitulo] = useState('')
    const [msg, setMsg] = useState('')
    const [error, setError] = useState()
    let context

    if (token) {
        context = useContext(UserContext)
    }


    useEffect(() => {
        const get_Data = async (sicoi) => {

            const result = await get_ProductosByCategoria_PerPage(0, sicoi)
            if (result) {
                const filterProductos = result.filter(unProducto => parseFloat(unProducto.Precio) > 0 && unProducto.Activo === true && unProducto.Id !== producto.Id)
                const relacionados = filterProductos.slice(0, 4)
                setRelacionados(relacionados)
            }

        }
        get_Data(Grupo.IdentificacionSicoi)
    }, [producto])


    const { Imagenes, Descripcion, Grupo } = producto

    const valorPrecio = generaUnPrecio(0, producto.Precio, precioUSD)



    const editaCarrito = async () => {
        let data = {
            Carrito: [
                {
                    CodigoSicoi: producto.CodigoSicoi,
                    Cantidad: "1"
                }
            ]

        }
        setLoading(true)

        const carrito = await get_Carrito(context.jwt.token)

        if(carrito.carrito.length === 0){
            const result = await edita_CarritoUsuario(context.jwt.token, data)
            if (result) {
                setModalProducto(true)
                setTitulo('Productos Agregados')
                setMsg(`Se han agregado ${producto.Nombre} a tu carrito`)
                setError(false)
            } else {
                setModalProducto(true)
                setTitulo('Ups')
                setMsg(`Ah ocurrido un error al intentar agregar  ${producto.Nombre} a tu carrito`)
                setError(true)
            }
            
        } else {
            for (const unProducto of carrito.carrito) {
                const temp = {
                    CodigoSicoi: unProducto.Producto.CodigoSicoi,
                    Cantidad: unProducto.Cantidad
                }
                data.Carrito.push(temp)
            }

            const result = await edita_CarritoUsuario(context.jwt.token, data)
            if (result) {
                await context.setItems(0)
                setModalProducto(true)
                setTitulo('Productos Agregados')
                setMsg(`Se han agregado ${producto.Nombre} a tu carrito`)
                setError(false)
            } else {
                setModalProducto(true)
                setTitulo('Ups')
                setMsg(`Ah ocurrido un error al intentar agregar  ${producto.Nombre} a tu carrito`)
                setError(true)
            }
        }

        

        setLoading(false)
        return


    }






    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    <div className="row mt-4 mb-4">
                        <div className="col-md-8 mx-auto mb-3">
                            <ContentCardUnProducto>
                                <div className="row">
                                    <div className="col-md-6 mx-auto">
                                        <ContentLinkCategoria>
                                            <Link to={`/Productos/ByCategoria/${Grupo.IdentificacionSicoi}`} className='mb-4'>
                                                <i className='bi bi-arrow-right-circle-fill'></i>
                                                <span>{Grupo.Nombre}</span>
                                            </Link>

                                        </ContentLinkCategoria>
                                        <ContentNombreUnProducto>
                                            <p>{producto.Nombre}</p>
                                        </ContentNombreUnProducto>




                                        {Imagenes.length > 0 ?
                                            <>
                                                {
                                                    Imagenes.length === 1 ?
                                                        <div className="row mb-2">
                                                            <div className="col-md-12 mx-auto">
                                                                <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${Imagenes[0].URLImagen}`} alt={`Rueda Digital Producto ${producto.Nombre}`} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-8 col-8 mx-auto">
                                                                    <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${Imagenes[0].URLImagen}`} alt={`Rueda Digital Producto ${producto.Nombre}`} />
                                                                </div>
                                                                <div className="col-md-4 col-4 align-self-center">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${Imagenes[1].URLImagen}`} alt={`Rueda Digital Producto ${producto.Nombre}`} />
                                                                        </div>
                                                                        <div className="col-md-12 mt-2">
                                                                            { Imagenes[2].URLImagen !== '' ?
                                                                                <ImagenPrincipalProducto src={`https://api.ruedadigital.com.ar/${Imagenes[2].URLImagen}`} alt={`Rueda Digital Producto ${producto.Nombre}`} />
                                                                                :
                                                                                null
                                                                            }
                                                                           
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            :
                                            <div className="row mb-2">
                                                <div className="col-md-12 mx-auto">
                                                    <ImagenPrincipalProducto src={SinFoto} alt='Rueda Digital Producto sin imagen' />
                                                </div>
                                            </div>

                                        }

                                        <div className="row mb-3 mt-2">
                                            <div className="col-md-6 col-6 align-self-center">
                                                <TextoDataUnProducto>
                                                    {
                                                        parseInt(producto.MetrosEstandard) === 1 ?
                                                        <>
                                                            Precio por unidad
                                                        </>
                                                        :
                                                        <>
                                                            Precio por rollo de {producto.MetrosEstandard.slice(0, -3)} mts.
                                                        </>
                                                    }
                                                    
                                                </TextoDataUnProducto>

                                            </div>
                                            <div className="col-md-6 col-6">
                                                <ContentPrecioUnProducto>
                                                   <FormatNumero numero={valorPrecio} />
                                                </ContentPrecioUnProducto>
                                            </div>
                                        </div>
                                        {
                                            producto.Stock === 0 ?
                                                <ProductoSinStock>
                                                    Producto sin stock.
                                                </ProductoSinStock>
                                                :
                                                <div className="row mb-3">
                                                    <div className="col-md-10">
                                                        {
                                                            token ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-8 col-10">
                                                                            <RedPrimaryButtonStyles onClick={() => editaCarrito()}>
                                                                                <i className='bi bi-cart'>    </i>
                                                                                Agregar al Carrito
                                                                            </RedPrimaryButtonStyles>
                                                                        </div>
                                                                    </div>

                                                                </>

                                                                :
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-8 col-10">
                                                                            <RedPrimaryButtonStyles onClick={() => setModalNoRegistrado(true)}>
                                                                                <i className='bi bi-cart'>    </i>
                                                                                Agregar al Carrito
                                                                            </RedPrimaryButtonStyles>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }

                                                    </div>

                                                </div>

                                        }



                                        <div className="row mb-4">
                                            {Descripcion ?
                                                <>
                                                    <ContentMessageSinDescripcion>
                                                        <div className="row">
                                                            <div className="col-md-4 col-4">
                                                                <TitulosDescripcion>Largo rollo</TitulosDescripcion>
                                                                <TextoDescripcion>{Descripcion.LargoRollo}</TextoDescripcion>
                                                            </div>
                                                            <div className="col-md-4 col-4">
                                                                <TitulosDescripcion>Ancho rollo</TitulosDescripcion>
                                                                <TextoDescripcion>{Descripcion.AnchoRollo}</TextoDescripcion>
                                                            </div>
                                                            <div className="col-md-4 col-4">
                                                                <TitulosDescripcion>Peso rollo</TitulosDescripcion>
                                                                <TextoDescripcion>{Descripcion.PesoRollo}</TextoDescripcion>
                                                            </div>
                                                        </div>
                                                    </ContentMessageSinDescripcion>

                                                    <div className="row mt-4">
                                                        <div className="col-md-12 mb-3">
                                                            {
                                                                Descripcion.Descripcion !== '' ?
                                                                    <>
                                                                        <TextoDescripcion>Descripción</TextoDescripcion>
                                                                        <TitulosDescripcion>{Descripcion.Descripcion}</TitulosDescripcion>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-md-12">
                                                            {
                                                                Descripcion.Observaciones !== '' ?
                                                                    <>
                                                                        <TextoDescripcion>Observaciones</TextoDescripcion>
                                                                        <TitulosDescripcion>{Descripcion.Observaciones}</TitulosDescripcion>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <ContentMessageSinDescripcion>
                                                        <p>Lo sentimos, este producto aún no tiene cargada su descripción.</p>
                                                        <p>Si necesita mas información sobre este producto por favor contáctese con nosotros.</p>
                                                    </ContentMessageSinDescripcion>
                                                </>

                                            }

                                        </div>



                                    </div>

                                </div>


                            </ContentCardUnProducto>

                        </div>

                        <div className="row">
                            <div className="col-md-11 col-12 mx-auto">
                                <ContentProductosRelacionados>
                                    <div className="row mb-2">
                                        <div className="col-md-10 col-8 align-self-center">
                                            <TitulosProductosRelacionados>
                                                Productos relacionados
                                            </TitulosProductosRelacionados>
                                        </div>
                                        <div className="col-md-2 col-4 align-self-center">
                                            <ContentLinkRelacionados>
                                                <Link to={`/Productos/ByCategoria/${Grupo.IdentificacionSicoi}`}>
                                                    Ver todos
                                                </Link>
                                            </ContentLinkRelacionados>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {
                                            relacionados.map(unProducto => (
                                                <CardsProductos producto={unProducto} precioDolar={precioUSD} key={unProducto.Id} />
                                            ))
                                        }
                                    </div>

                                </ContentProductosRelacionados>


                            </div>
                        </div>

                    </div>

                    <ModalUsuarioNoRegistrado
                        data={ModalNoRegistrado}
                        icono='bi bi-person-x-fill'
                        titulo='Ups ...'
                        message='Al parecer no estar registrado, para realizar esta acción debes iniciar sesión o registrarte.'

                    />

                    <ModalProductoAgregado
                        data={modalProducto}
                        titulo={titulo}
                        message={msg}
                        error={error}
                        link=''
                        texto=''

                    />

                    
                </>

            }

        </>


    );
}



export default UnProducto;