import styled  from 'styled-components';

export const ContentItemOrden = styled.div`
    width: 100%;
    background-color: var(--Color-Blanco);
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    padding: 25px 50px;
    margin-top: 2%;
    margin-bottom: 2%;

    @media (max-width: 480px) {
        width: 95%;
        margin: auto;
        margin-bottom: 4%;
    }

`

export const LogoEnOrden = styled.img`
    width: 100%;

    @media (max-width: 480px) {
        width: 50%;
        margin-left: 25%;
    }

`

export const IdOrden = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    color: var(--Color-Negro);
    margin-bottom: 0;
    & > span {
        color: var(--Color-Red-Primary);
        margin-left: 5px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
        text-align: center;

    }

`

export const TotalOrden = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    margin-bottom: 0;

    &>span{
        color: var(--Color-Red-Primary);
        margin-left: 5px;
    }

    &>sup{
        color: var(--Color-Red-Primary);
    }

    &>small{
        color: var(--Color-Red-Primary);
    }

    @media (max-width: 480px) {
        font-size: 14px;
        text-align: center;


    }
`

export const BtnVerOrdenes = styled.button`
    font-family: var(--Font-DMSans-Regular);
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: var(--Color-Red-Primary);
    border: none;
    border-radius: 15px;
    color: var(--Color-Blanco);
    transition: all .25s ease-in;

    &:hover{
        background-color: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
        &>a{
            background-color: none;
            color: var(--Color-Red-Primary);
        }
        
    }

    &>a{
        text-decoration: none;
        color: white;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`

export const MessageSinOrden = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    text-align: center;

`

export const ImgRueda = styled.img`
    width: 30%;
    margin-left: 35%;
    margin-bottom: 2%;

`

export const ImagenPrincipalProducto = styled.img`
    width:70%;
    margin-left: 15%;
    border-radius: 10px;
`

export const TituloProducto = styled.p`
    font-family: var(--Font-DMSans-Bold);
    font-size: 16px;
    margin-bottom: 2%;
    color: var(--Color-Negro);

    &>span{
        margin-left: 5px;
        margin-right: 5px;
    }

    
    @media (max-width: 480px) {
        font-size: 14px;
        margin-top: 1%;
        text-align: center;
    }


`

export const ButonGenerarOrden = styled.button`
    background: var(--Color-Red-Primary);
    color: var(--Color-Blanco);
    font-family: var(--Font-DMSans-Regular);
    padding: 10px 15px;
    width: 80%;
    margin-left: 20%;
    border-radius: 10px;
    border: 1px solid var(--Color-Red-Primary);
    transition: all .25s linear;
    &:hover{
        background: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
    }

    &>i{
        margin-right: 10px;
    }

    @media (max-width: 480px) {
        width: 100%;
        margin-left: 0;
        font-size: 14px;
    }

`

export const ButonPagarConMercadoPago = styled.button`
    background: var(--Color-Red-Primary);
    color: var(--Color-Blanco);
    font-family: var(--Font-DMSans-Regular);
    padding: 10px 15px;
    width: 80%;
    margin-right: 20%;
    border-radius: 10px;
    border: 1px solid var(--Color-Red-Primary);
    transition: all .25s linear;
    &:hover{
        background: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
    }

    &>i{
        margin-left: 10px;
    }

    @media (max-width: 480px) {
        width: 100%;
        margin-left: 0;
    }
`