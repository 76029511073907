import styled from 'styled-components';

export const ContentCardsAyuda = styled.div`
    padding: 20px;
    background-color: var(--Color-Blanco);
    border-radius: 15px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    text-align: center;
    transition: all .25s linear;

    @media (max-width: 480px) {
        width: 95%;
        margin: auto;
    }
`

export const RespuestaPregunta = styled.p`
    font-family: var(--Font-DMSans-Regular);
    font-size: 16px;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`

