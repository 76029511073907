import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";

import { get_Productos_ByCategoria } from "../services/Productos";
import { get_ValorDolar } from "../services/CotizacionDolar";

import Loading from "../components/Loading/Loading";

import {TituloPrincipal  } from "../components/Titulos/Titulos";

import SinDatos from "../components/Errors/SinDatos";
import CardsProducto from "../components/CardsProductos/CardsProductos";

const ProductosXCategoria = () => {


    const {sicoi} = useParams()
    const [loading, setLoading] = useState(false)
    const [categoria, setCategoria] = useState('')
    const [productos, setProductos] = useState([])
    const [precioUDS, setPrecioUDS] = useState()


    useEffect(()=> {
        const getData = async(sicoi) => {
            setLoading(true)
            const dolar = await get_ValorDolar()
            if(dolar){
                setPrecioUDS(dolar)
                const result = await get_Productos_ByCategoria(sicoi)
                if(result){
                    if(result.length > 0){
                        setCategoria(result[0].Grupo.Nombre)
                        const filterProductos = result.filter(unProducto => parseFloat(unProducto.Precio) > 0)
                        setProductos(filterProductos)
                    }
                }
            }else{
                setProductos(null)
            }

            setLoading(false)
        }

        getData(sicoi)
    },[sicoi])


    if(loading){
        return (
            <Loading />
        )
    }




    return (
        <>
            { productos ?
                <>
                    <TituloPrincipal texto={categoria}  />
                    <div className="row">
                        <div className="col-md-8 col-11 mx-auto">
                            <div className="row">
                                {
                                    productos.length > 0 ?

                                    <>
                                        {
                                            productos.map(unProducto => (
                                                <CardsProducto producto={unProducto} key={unProducto.Id} precioDolar={precioUDS} />
                                            ))
                                        }
                                    </>
                                    :
                                    <div className="col-md-12 mx-auto">
                                        <SinDatos 
                                            title='Lo sentimos'
                                            message='Al parecer no hay productos disponibles en esta categoria.'
                                        />
        
                                    </div>

                                }
     
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="mt-4 mb-4">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <SinDatos 
                                title='Lo sentimos'
                                message='Al parecer lo que estas buscando ya no se encuentra disponible'
                            />

                        </div>
                    </div>
                </div>
            }
           
        </>
    );
}


export default ProductosXCategoria;