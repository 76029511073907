import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import { get_OrdenCliente } from "../services/Users";
import { get_Token } from "../helpers/getToken";

import Loading from "../components/Loading/Loading";
import {TituloPrincipal  } from "../components/Titulos/Titulos";
import CardOrden from "../components/CardsOrdenes/CardVerUnaOrden";


const UserVerOrden = () => {

    const {hash} = useParams()
    const [loading, setLoading] = useState(false)
    const [orden, setOrden] = useState()


    useEffect(()=>{
        const getData = async() => {
            setLoading(true)
            const token = get_Token()
            if(!token){
                window.location.href='/Ingresar'
            }else{
                const result = await get_OrdenCliente(token.token, hash)
                console.log(result)
                if(result){
                    setOrden(result)
                }else{
                    setOrden(null)
                }
            }
            setLoading(false)

        }
        getData()
    }, [])


    return (
        <>
        { loading ?
            <Loading />
            :
            <>
                <TituloPrincipal texto='Ver una orden' />
                <CardOrden orden={orden} />
            </>

        }            
        </>
    );
}



export default UserVerOrden;